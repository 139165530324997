import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./screens/Home";
import SerlianProfil from "./screens/SerlianProfile";
import ListsManagement from "./screens/admin/lists/ListsManagement";
import EditList from "./screens/admin/lists/EditList";
import SerliansList from "./screens/admin/lists/SerliansList";
import GestionSkills from "./screens/admin/skills/SkillsManagement";
import AddList from "./screens/admin/lists/AddList";
import SerlianContext from "./context";
import Header from "./components/Header";
import IsPending from "./components/IsPending";
import Contact from "./screens/Contact";
import ErrorSerlianProfil from "./components/ErrorSerlianProfile";
import { getSerlians } from "./utils/fetch/fetchUser";
import "../client/css/main.scss";
import MissionsManagement from "./screens/admin/missions/MissionsManagement";
import SerlianManagement from "./screens/admin/serlian/SerlianManagement";
import AddSerlian from "./screens/admin/serlian/AddSerlian";
import EditSerlian from "./screens/admin/serlian/EditSerlian";

class AppAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serlians: [],
      serliansToShow: [],
      width: 0,
      isPending: true,
      errorConn: false,
    };
  }

  componentDidMount() {
    this.getSerlians();
    this.getSerlianConnected();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  getSerlians = () => {
    getSerlians((serlians) => {
      if (serlians.message && serlians.message === "CONNECTION_FAILED") {
        this.setState({ errorConn: true, isPending: false });
      } else if (serlians.message && serlians.message === "NO_RESULT") {
        this.setState({ isPending: false });
      } else {
        const sortedSerlians = serlians
          .filter((serlian) => !serlian.archive)
          .sort((a, b) => {
            const firstNameComparison = a.firstname.localeCompare(b.firstname);
            if (firstNameComparison !== 0) {
              return firstNameComparison;
            }
            return a.lastname.localeCompare(b.lastname);
          });

        this.setState({
          serlians: sortedSerlians,
          isPending: false,
          serliansToShow: sortedSerlians,
        });
      }
    });
  };

  getSerlianConnected = () => {
    fetch("/api/global/me", {
      method: "GET",
      credentials: "include", //on inclut les cookies
      headers: { Accept: "application/json" },
    })
      .then((r) => r.json())
      .then((serlianConnected) => {
        this.setState({ serlianConnected });
      });
  };

  //function to set serlians to show in home page according to state update
  setSerlians = (serliansToShow) => {
    this.setState({ serliansToShow });
  };

  setSerliansToShow = (serliansToShow) => {
    this.setState({ serliansToShow });
  };

  render() {
    const context = {
      serlians: this.state.serlians,
      serliansToShow: this.state.serliansToShow,
      serlianConnected: this.state.serlianConnected,
      isMobile:
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i),
      isTablet:
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/Tablet/i),
      maxLength: 10485760,
    };

    return (
      <SerlianContext.Provider value={context}>
        <Router>
          {this.state.isPending ? (
            <div>
              <Header
                user={context.serlianConnected}
                setSerlian={this.setSerlians}
                setSerliansToShow={this.setSerliansToShow}
              />
              <IsPending />
            </div>
          ) : this.state.errorConn ? (
            <div>
              <Header
                user={context.serlianConnected}
                setSerlian={this.setSerlians}
                setSerliansToShow={this.setSerliansToShow}
              />
              <div className="container container--column container--page-error">
                <h2>Oups...</h2>
                <p>Une erreur de connexion est survenue...</p>
              </div>
            </div>
          ) : (
            <div>
              <Header
                user={context.serlianConnected}
                setSerlian={this.setSerlians}
                setSerliansToShow={this.setSerliansToShow}
              />
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <Home
                      {...props}
                      setSerlians={this.setSerlians}
                      user={context.serlianConnected}
                    />
                  )}
                />
                <Route path="/serliens/gestion" component={SerlianManagement} />
                <Route path="/serliens/add" component={AddSerlian} />
                <Route path="/serliens/edit" component={EditSerlian} />
                <Route path="/serliens/error" component={ErrorSerlianProfil} />
                <Route path="/serliens/:name" component={SerlianProfil} />
                <Route
                  path="/contact"
                  render={(props) => (
                    <Contact
                      {...props}
                      serlianConnected={this.state.serlianConnected}
                    />
                  )}
                />
                <Route path="/lists/create" component={AddList} />
                <Route path="/lists/display" component={ListsManagement} />
                <Route path="/lists/update" component={EditList} />
                <Route path="/lists/:listId" component={SerliansList} />
                <Route
                  path="/missions/gestion"
                  component={MissionsManagement}
                />
                <Route path="/skills/gestion" component={GestionSkills} />
              </Switch>
            </div>
          )}
        </Router>
      </SerlianContext.Provider>
    );
  }
}

ReactDOM.render(<AppAdmin />, document.getElementById("app"));
