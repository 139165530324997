import React, { useState, useEffect, useContext } from "react";
import SerlianCard from "./SerlianCard";
import SerlianContext from "../context";

const Serlians = (props) => {
  const context = useContext(SerlianContext);

  const [serlians, setSerlians] = useState(context.serliansToShow);

  useEffect(() => {
    initSerlians();
  }, [context.serliansToShow]);

  const initSerlians = () => {
    if (serlians.length === 0) {
      setSerlians(context.serliansToShow);
    }
  };

  return serlians.length === 0 ? (
    <div className="container container--page-error">
      <h2>Oups...</h2>
      <p>Il semble qu'il n'y ait aucun Serlien de renseigné.</p>
    </div>
  ) : (
    <>
      <div className="container container--centered">
        {context.serliansToShow.length ? (
          context.serliansToShow
            .filter((serlian) => !serlian.archive)
            .sort((a, b) => {
              const firstNameComparison = a.firstname.localeCompare(
                b.firstname
              );
              if (firstNameComparison !== 0) {
                return firstNameComparison;
              }
              return a.lastname.localeCompare(b.lastname);
            })
            .map((s, index) => (
              <SerlianCard
                key={`serlian-card-${s.email}-${index}`}
                serlian={s}
              />
            ))
        ) : (
          <span>Aucun serlien trouvé.</span>
        )}
      </div>
    </>
  );
};

export default Serlians;
