import React, {Component} from "react";
import Skills from "./Skills";
import SerlianContext from "../../context";
import {FaAt} from "react-icons/fa";
import {MdLocationOn} from "react-icons/md";
import Conferences from "./conferences/Conference";
import Formations from "./formations/Formations";
import "./informations.scss";

class Informations extends Component {

    static contextType = SerlianContext;

    constructor(props) {
        super(props);
        this.state = {
            serlian : this.props.serlian
        }
    }

    componentDidUpdate() {
        if(this.props.serlian !== this.state.serlian){
            this.setState({serlian: this.props.serlian});
        }
    }

    render() {
        const {serlian} = this.state;
        return (
            <div className="profil-component informations">
                {
                    serlian &&
                        <>
                            {
                                !this.context.isMobile &&
                                    <div className="identity-card">
                                        <div className={"picture" + (this.context.serlianConnected.role === "admin" ? " edit-picture": "")}>
                                            <div className="roundedImage">
                                                <img className="profil-picture" src={serlian.picture} alt="photo"/>
                                                {
                                                    this.context.serlianConnected.role === "admin" &&
                                                        <div className="middle" onClick={() => this.FileUpdate.click()}>
                                                            <p className="text"><strong>Modifier l'image</strong></p>
                                                        </div>
                                                }
                                            </div>
                                            {
                                                this.context.serlianConnected.role === "admin" &&
                                                    <input
                                                        type="file"
                                                        accept="image/jpeg"
                                                        hidden
                                                        ref={(file) => this.FileUpdate = file}
                                                        onChange={this.props.updateProfilPicture}
                                                    />
                                            }
                                        </div>
                                        <div className="presentation">
                                            <h2>{serlian.firstname} {serlian.lastname}</h2>
                                            <p className="job">{serlian.role.substr(0, 1).toUpperCase() + serlian.role.substring(1).toLowerCase()}</p>
                                            <div className="infos-serlian">
                                                <div className="icone">
                                                    <FaAt className="icons"/>
                                                    <p> {serlian.email}</p>
                                                </div>
                                                {
                                                    serlian.city &&
                                                        <div className="icone">
                                                            <MdLocationOn className="icons"/>
                                                            <p className="city">{serlian.city}</p>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                            }
                            <Skills idSerlian={serlian.id} updateSerlian={this.props.updateSerlian} isEditable={this.props.isEditable}/>
                            <Conferences idSerlian={serlian.id} isEditable={this.props.isEditable}/>
                            <Formations idSerlian={serlian.id} formations={serlian.formations} isEditable={this.props.isEditable}/>
                        </>
                }
            </div>
        );
    }
}

export default Informations;