import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SerlianContext from "../context";
import { FaSearch } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import { getSkills } from "../utils/fetch/fetchSkills";
import { getMissions } from "../utils/fetch/fetchMissions";

const SearchSerlian = ({ setSerlians, searchField, setSearchField }) => {
  const serlianContext = useContext(SerlianContext);
  const serlians = serlianContext.serlians;

  const [skills, setSkills] = useState([]);
  const [missions, setMissions] = useState([]);

  const history = useHistory();

  // Fetch skills and missions when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      await getSkills((skills) => {
        setSkills(skills);
      });
      await getMissions((missions) => {
        setMissions(missions);
      });
    };

    fetchData();
  }, []);

  // Remove accents from a string
  const noAccent = (string) => {
    const accent = [
      /[\300-\306]/g,
      /[\340-\346]/g, // A,
      /[\310-\313]/g,
      /[\350-\353]/g, // E, e
      /[\314-\317]/g,
      /[\354-\357]/g, // I, i
      /[\322-\330]/g,
      /[\362-\370]/g, // O, o
      /[\331-\334]/g,
      /[\371-\374]/g, // U, u
      /[\321]/g,
      /[\361]/g, // N, n
      /[\307]/g,
      /[\347]/g, // C, c
    ];
    const noAccent = [
      "A",
      "a",
      "E",
      "e",
      "I",
      "i",
      "O",
      "o",
      "U",
      "u",
      "N",
      "n",
      "C",
      "c",
    ];
    accent.forEach((acc, i) => {
      string = string.replace(acc, noAccent[i]);
    });
    return string;
  };

  // Get skills based on user input
  const getSerlianBySkills = (matchedSkillArray) => {
    const serliansList = serlians;
    const updatedSerlians = [];

    serliansList.forEach((serlian) => {
      serlian.skills &&
        serlian.skills.forEach((skillId) => {
          skillId &&
            matchedSkillArray.forEach((matchedSkill) => {
              if (
                matchedSkill.id_skill === skillId &&
                !updatedSerlians.includes(serlian)
              ) {
                updatedSerlians.push(serlian);
              }
            });
        });
    });

    return updatedSerlians;
  };

  // Handle input change event
  const onChange = (event) => {
    const source = event.target;
    setSearchField(source.value);
    search(source.value);
    history.push("/");
  };

  // Handle backspace key)
  const onKeyDown = (event) => {
    if (event.key === "Backspace") {
      const updatedSearchField = searchField.slice(0, -1);
      setSearchField(updatedSearchField);
      search(updatedSearchField);
    }
  };

  // Global search including skills, name, and clients
  const search = (search) => {
    const searchSerlianResult = searchSerlian(search);
    const searchSkillResult = searchSkills(search);
    const searchClientResult = searchClient(search);

    const allResults = [];

    if (searchSerlianResult) {
      allResults.push(...searchSerlianResult);
    }
    if (searchSkillResult) {
      allResults.push(...searchSkillResult);
    }
    if (searchClientResult) {
      allResults.push(...searchClientResult);
    }

    // Filter out duplicates
    const finalResult = [];
    allResults.forEach((serlian) => {
      if (!finalResult.includes(serlian)) {
        finalResult.push(serlian);
      }
    });
    const sortedSerliansResults = finalResult
      .filter((serlian) => !serlian.archive)
      .sort((a, b) => {
        const firstNameComparison = a.firstname.localeCompare(b.firstname);
        if (firstNameComparison !== 0) {
          return firstNameComparison;
        }
        return a.lastname.localeCompare(b.lastname);
      });

    setSerlians(sortedSerliansResults);
  };

  // Search serlians based on name
  const searchSerlian = (search) => {
    search = search.toLowerCase();
    search = search.split(",").filter((item) => item.trim() !== "");
    const serliansList = serlians;

    if (search.length === 0) {
      return serlians;
    } else {
      const updatedSerlians = serliansList.filter((serlian) => {
        const firstname = serlian.firstname.toLowerCase();
        const firstnameNA = noAccent(firstname);
        const lastname = serlian.lastname.toLowerCase();
        const lastnameNA = noAccent(lastname);

        return search.some((searchItem) => {
          const names = searchItem
            .split(" ")
            .filter((item) => item.trim() !== "");

          return names.some((name, index) => {
            if (names.length === 1) {
              return (
                firstname.startsWith(name) ||
                lastname.startsWith(name) ||
                firstnameNA.startsWith(name) ||
                lastnameNA.startsWith(name)
              );
            } else if (names.length > 1) {
              return (
                (firstname.startsWith(name) &&
                  lastname.startsWith(names[index + 1])) ||
                (firstnameNA.startsWith(name) &&
                  lastnameNA.startsWith(names[index + 1]))
              );
            }
          });
        });
      });

      return updatedSerlians;
    }
  };

  // Search serlians based on skills
  const searchSkills = (search) => {
    search = search.toLowerCase();
    search = search.split(",");
    search = search.filter((item) => item.trim() !== "");
    const length = search.length;

    if (length === 0) {
      setSerlians(serlians);
    } else {
      const matchSkills = skills.filter((skill) => {
        return search.some((search) => {
          search = search.trim();
          return skill.skill.toLowerCase().startsWith(search);
        });
      });
      const updatedSerlians = getSerlianBySkills(matchSkills);
      return updatedSerlians;
    }
  };

  // Search serlians based on client
  const searchClient = (search) => {
    search = search.toLowerCase();
    search = search.split(",");
    search = search.filter((item) => item.trim() !== "");
    const length = search.length;
    const serliansOfClient = [];

    if (length === 0) {
      setSerlians(serlians);
    } else {
      const matchingMissions = missions.filter((mission) => {
        return search.some((search) => {
          search = search.trim();
          return mission.client_name.toLowerCase().startsWith(search);
        });
      });

      matchingMissions.forEach((mission) => {
        if (mission.id_user) {
          const serlianFound = getSerlian(mission.id_user);
          if (!serliansOfClient.includes(serlianFound) && serlianFound) {
            serliansOfClient.push(serlianFound);
          }
        }
      });

      return serliansOfClient;
    }
  };

  // Get a serlian by id and add to updatedSerlian
  const getSerlian = (id) => {
    const serlianFound = serlians.find((serlian) => serlian.id === id);
    return serlianFound;
  };

  // Reset search field and set serlians to the original list
  const resetField = () => {
    setSearchField("");
    setSerlians(serlians);
  };

  return (
    <div className="search__form">
      <div className="search__container">
        <div className="search__logo">
          <FaSearch style={{ color: "#3DB8D7" }} />
        </div>
        <input
          id="input"
          className="search__input"
          type="text"
          value={searchField}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder="Serlien, entreprise, compétence"
        />
        <button
          onClick={resetField}
          type="Reset"
          className={
            searchField ? "search__cross-button show" : "search__cross-button"
          }
        >
          <TiDelete />
        </button>
      </div>
    </div>
  );
};

export default SearchSerlian;
