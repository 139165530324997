export const updateFormations = (formations, idSerlian) => {
    return fetch(`/api/formations/add`, {
        method: 'PUT',
        credentials : 'include', //on inclut les cookies
        headers : {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body : JSON.stringify({
            formations,
            idSerlian
        })
    })
        .then(response => response.json())
};