export const deleteMission = (id, callback) => {
  fetch("/api/missions", {
    method: "DELETE",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      id,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      callback && callback(response);
    });
};

export const updateOrAddMission = (update, missions, callback) => {
  fetch("/api/missions", {
    method: update ? "PUT" : "POST",
    credentials: "include", //on inclut les cookies
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      missions: missions,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      callback && callback(response);
    });
};
