import React, {Component} from "react";
import {IoIosCalendar} from "react-icons/io";
import {MdPlace} from "react-icons/md";
import moment from "moment";
import SerlianContext from "../../context";
import {getUserMissions} from "../../utils/fetch/fetchMissions";
import './missions.scss';

moment.locale("fr");

class Missions extends Component {

    static contextType = SerlianContext;

    constructor(props) {
        super(props);
        this.state = {
            missions: [],
            idSerlian: this.props.idSerlian
        }
    }

    componentDidMount() {
        this.getUserMissions();
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        return prevProps;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.idSerlian !== this.state.idSerlian){
            this.setState({idSerlian: this.props.idSerlian}, this.getUserMissions());
        }
    }

    getUserMissions = () => {
        getUserMissions(this.props.idSerlian, (missions) => {
            this.setState({missions});
        })
    };

    render() {
        const {missions} = this.state;
        return(
            <div className="profil-component mission">
                <div className="profil-component-header">
                    <h3>Missions</h3>
                </div>
                <div className="clients">
                    {
                        missions.map((mission, index) => {
                            let dateDebut = moment(mission.begin_time).format("MM/YYYY");
                            let dateFin = moment(mission.end_time).format("MM/YYYY");
                            return (
                                <div className="client-container" key={index}>
                                    <div className="timeline-point" />
                                    <div className="client">
                                        <h3>{mission.client_name}</h3>
                                        <div className="mission-info">
                                            <span className="date-end">
                                                <IoIosCalendar/>
                                                {
                                                    mission.end_time && new Date().getTime() > new Date(mission.end_time).getTime() ?
                                                        <span>Fin : {dateFin} </span>
                                                    :
                                                        <i>en cours</i>
                                                }
                                            </span>
                                            {
                                                mission.workplace &&
                                                <span className="workplace"><MdPlace/>{mission.workplace}</span>
                                            }
                                        </div>
                                        <span className="date-start">{dateDebut}</span>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}

export default Missions;