import React from "react";
import { PrimaryButton } from "./Buttons";

const EditHomeBaseline = (props) => {
  const { text } = props;

  return (
    <form
      className={props.clazz}
      onClick={(e) => props.toggleModal(e.target)}
      onSubmit={props.handleSubmit}
    >
      <div className="form--modal__container overflow-auto">
        <div className="container container--column container--centered">
          <h2>Editer le texte</h2>
        </div>
        <label className="form__control">
          <span>Texte</span>
          <textarea
            className="form__input"
            onChange={props.handleChange}
            value={text}
          />
        </label>
        <div className="container container--column container--centered">
          <PrimaryButton id="btn-confirm" value={props.valueButton} />
        </div>
      </div>
    </form>
  );
};

export default EditHomeBaseline;
