import React, {Component} from "react";
import {Link} from "react-router-dom";
import {LogOutButton, UserButton} from "../buttons/Buttons";
import {MdAccountCircle, MdMessage} from "react-icons/md";
import {FaSignOutAlt} from "react-icons/fa";
import {FiEdit3, FiPlus} from "react-icons/fi";
import "./menu.scss";

class Menu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openMenu: false
        }
    }

    componentDidMount() {
        document.addEventListener("click", (e) => {
            if(e.target.id !== "user-picture") {
                this.setState({openMenu: false})
            }
        });
    }

    getFirstAndLastName = () => {
        let mail = this.props.user.email;
        let split = mail.split("@");
        return split[0];
    }

    displayMenu = (e) => {
        e.stopPropagation();
        this.setState({openMenu: !this.state.openMenu});
    };


    render() {
        const {user} = this.props;
        return (
                <div className="user-menu">
                    {
                        user &&
                            <>
                                <UserButton user={user} displayMenu={this.displayMenu}/>
                                <div className={"menu-container" + (this.state.openMenu ? " display" : "")} id="menu-container">
                                    <div className="user-name">
                                        <span>{user.name ? user.name : user.email}</span>
                                    </div>
                                    <div className="menu-list">
                                        {
                                            user.id ?
                                                <Link to={{pathname: `/serliens/${this.getFirstAndLastName()}`}}><div className="link"><MdAccountCircle />Mon profil</div></Link>
                                            :
                                                <Link to={{pathname: "/serliens/error"}}><div className="link"><MdAccountCircle />Mon profil</div></Link>

                                        }
                                        <Link to={{pathname: "/contact"}}><div className="link"><MdMessage />Contact</div></Link>
                                        <LogOutButton icon={<FaSignOutAlt />}/>
                                    </div>
                                    {
                                        user.role === "admin" &&
                                            <>
                                                <div className="admin-title">
                                                    <span>Administrateur</span>
                                                </div>
                                                <div className="menu-list">
                                                    <div className="caption">
                                                        <span>Missions</span>
                                                        <Link to={{pathname: `/missions/add`}}><div className="link"><FiPlus />Ajouter missions </div></Link>
                                                        <Link to={{pathname: `/missions/gestion`}}><div className="link"><FiEdit3 />Gestion missions </div></Link>
                                                    </div>
                                                    <div className="caption">
                                                        <span>Serliens</span>
                                                        <Link to={{pathname: `/serliens/add`}}><div className="link"><FiPlus />Ajouter Serlien </div></Link>
                                                        <Link to={{pathname: `/serliens/gestion`}}><div className="link"><FiEdit3 />Gestion Serliens </div></Link>
                                                    </div>
                                                    <div className="caption">
                                                        <span>Compétences</span>
                                                        <Link to={{pathname: `/skills/gestion`}}><div className="link"><FiEdit3 />Gestion Compétences </div></Link>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                            </>
                    }
                </div>
        )
    }
}

export default Menu;