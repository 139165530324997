import React, { useContext, useState, useEffect } from "react";
import Serlians from "../components/Serlians";
import SerlianContext from "../context";
import SearchSerlianByClient from "../components/SearchSerlianByClient";
import { FiEdit3 } from "react-icons/fi";
import EditHomeBaseline from "../components/EditHomeBaseline";
import {
  addBaseline,
  getBaseline,
  deleteBaseline,
  updateBaseline,
} from "../utils/fetch/fetchHomeBaseline";

const Home = (props) => {
  const context = useContext(SerlianContext);

  const [clazz, setClazz] = useState("form--modal");
  const [text, setText] = useState("");
  const [valueButton, setValueButton] = useState("Mettre à jour");

  const mobileScreen = context.isMobile;

  useEffect(() => {
    getBaseline((baseline) => {
      setText(baseline);
    });
  }, []);

  const handleChange = (e) => {
    const maxLength = context.maxLength;
    const { value } = e.target;
    if (value.length <= maxLength) setText(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentTarget = e.currentTarget;
    const update = valueButton === "Mettre à jour";
    setValueButton("Envoyer");
    toggleModalEdit(currentTarget);
    try {
      await deleteBaseline();
      await addBaseline(text);
    } catch (error) {
      console.error("Error updating baseline:", error);
    }
  };

  const toggleModalEdit = (target) => {
    if (target.classList.contains("toggle-add")) {
      setClazz("form--modal");
    }
  };

  return (
    <>
      <div className="container">
        <div className="container container--column container--centered">
          <h2>DÉCOUVREZ LA</h2>
          <h1>
            TEAM <span className="serli">SERLI</span>
          </h1>
          <p className="baseline">{text}</p>
          {props.user.role && props.user.role === "admin" && (
            <div
              className="btn btn--round"
              onClick={() => {
                setClazz("form--modal toggle-add");
                setText(text);
                setValueButton("Mettre à jour");
              }}
            >
              <FiEdit3 />
            </div>
          )}
        </div>
      </div>
      {mobileScreen === null && (
        <SearchSerlianByClient setSerlians={props.setSerlians} />
      )}
      <Serlians setSerlian={props.setSerlians} />;
      <EditHomeBaseline
        toggleModal={toggleModalEdit}
        clazz={clazz}
        text={text}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        valueButton={valueButton}
      />
    </>
  );
};

export default Home;
