import { PrimaryButton } from "../../../components/Buttons";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Toast from "../../../components/Toast";
import SerlianContext from "../../../context";
import { updateSerlian } from "../../../utils/fetch/fetchUserAdmin";

const EditSerlian = (props) => {
  const context = useContext(SerlianContext);
  const history = useHistory();

  const [state, setState] = useState({
    toast: "",
    errorPicture: false,
    errorMailFormat: false,
    errorMailExist: false,
    valueButton: "Mettre à jour",
    disabledButton: false,
    inputFileKey: Date.now(),
    serlian: {
      ...props.location.state.serlian,
      haspicture: props.location.state.serlian.haspicture,
    },
  });

  useEffect(() => {
    return () => {
      clearTimeout(state.toastTimer);
    };
  }, [state]);

  const handleChange = (e) => {
    let target = e.currentTarget;
    const { serlian } = state;

    if (target.files) {
      state.picture = target.files[0];
      const file = target.files[0];
      const img = new Image();

      img.onload = function () {
        if (this.width !== this.height) {
          setState((prevState) => ({ ...prevState, errorPicture: true }));
        } else {
          setState((prevState) => ({ ...prevState, errorPicture: false }));
        }
      };
      img.onerror = function () {
        alert("not a valid file: " + file.type);
      };
      img.src = window.URL.createObjectURL(file);
      setState((prevState) => ({
        ...prevState,
        serlian: { ...serlian, haspicture: true },
      }));
    } else {
      if (target.value.length < context.maxLength) {
        serlian[target.name] = target.value;
      }
      if (
        target.name === "email" &&
        (target.value.endsWith("@serli.com") || target.value === "")
      ) {
        setState((prevState) => ({
          ...prevState,
          errorMailFormat: false,
          errorMailExist: false,
        }));
      } else if (
        target.name === "email" &&
        !target.value.endsWith("@serli.com")
      ) {
        setState((prevState) => ({
          ...prevState,
          errorMailFormat: true,
          errorMailExist: false,
        }));
      }
    }
    setState((prevState) => ({
      ...prevState,
      serlian: { ...serlian },
    }));
  };

  const updateSerlianInfo = () => {
    const { serlian, picture } = state;
    const formData = new FormData();
    formData.append("picture", picture);
    formData.append("serlian", JSON.stringify(serlian));

    updateSerlian(formData, (response) => {
      if (response.name === "success") {
        setState((prevState) => ({
          ...prevState,
          toast: "SUCCESS",
          errorMailExist: false,
          inputFileKey: Date.now(),
        }));
        if (response.picture && response.picture !== "success") {
          setState({ toast: "ERROR_PICTURE" });
        }

        setTimeout(() => {
          history.push("/serliens/gestion");
        }, 1800);
      } else if (response.message === "EMAIL_EXIST") {
        setState({ errorMailExist: true });
      } else {
        setState({ toast: "ERROR" });
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      valueButton: <div className="loader" />,
      disabledButton: true,
    }));

    updateSerlianInfo();

    setTimeout(
      function () {
        setState({ toast: "" });
      }.bind(this),
      8000
    );
  };

  const resetField = () => {
    setState({
      ...state,
      serlian: { ...state.serlian, picture: null },
      errorPicture: false,
      inputFileKey: Date.now(),
    });
  };

  const renderToast = () => {
    switch (state.toast) {
      case "SUCCESS":
        return (
          <Toast
            type="success"
            title="Succès"
            message="Le Serlien a bien été enregistré"
          />
        );
      case "ERROR":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="Le Serlien n'a pas pu être enregistré"
          />
        );
      case "ERROR_PICTURE":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="L'image n'a pas pu être enregistrée, veuillez réessayer sur le profil de l'utilisateur."
          />
        );
      default:
        return null;
    }
  };

  const {
    serlian,
    errorMailFormat,
    errorMailExist,
    errorPicture,
    inputFileKey,
    valueButton,
  } = state;

  return (
    <div className="container container--column">
      {renderToast()}
      <div className="container container--column container--centered">
        <h2>Editer les informations</h2>
        <em>
          Les champs suivis d'une étoile <u>doivent</u> être remplis
        </em>
      </div>
      <form className="form" onSubmit={handleSubmit}>
        <label className="form__control">
          <span>Prénom *</span>
          <input
            type="text"
            className="form__input"
            name="firstname"
            onChange={handleChange}
            value={serlian.firstname}
            required
          />
        </label>
        <label className="form__control">
          <span>Nom *</span>
          <input
            type="text"
            className="form__input"
            name="lastname"
            onChange={handleChange}
            value={serlian.lastname}
            required
          />
        </label>
        <label className="form__control">
          <span>
            Email *
            {errorMailFormat && (
              <em>Cette adresse mail n'est pas de type serli.com</em>
            )}
            {errorMailExist && <em>Cette adresse mail est déjà utilisée</em>}
          </span>
          <input
            type="email"
            className={
              errorMailFormat || errorMailExist
                ? "form__input form__error"
                : "form__input"
            }
            name="email"
            onChange={handleChange}
            value={serlian.email}
            required
          />
        </label>
        <label className="form__control">
          <span>Poste *</span>
          <input
            type="text"
            className="form__input"
            name="job"
            onChange={handleChange}
            value={serlian.job}
            required
          />
        </label>
        <label className="form__control">
          <span>Agence</span>
          <select
            className="form__input"
            name="city"
            onChange={handleChange}
            value={serlian.city}
          >
            <option value="">Selectionner une option</option>
            <option value="Agence de Niort">Agence de Niort</option>
            <option value="Agence de Poitiers">Agence de Poitiers</option>
          </select>
        </label>
        <label className="form__control">
          <span>
            {/* {serlian.picture && <a href={serlian.picture}>Photo actuelle</a>} */}
            {serlian.picture && (
              <>
                <p>Photo actuelle</p>
                <div>
                  <img src={serlian.picture} className=" form__picture" />
                </div>
              </>
            )}
          </span>
          <span>
            Choisir une photo
            {errorPicture && (
              <em>L'image doit être de dimension carrées (ex. 500x500)</em>
            )}
          </span>
          <div className="form__input-container">
            <input
              type="file"
              onChange={handleChange}
              key={inputFileKey}
              className={
                errorPicture ? "form__input form__error" : "form__input"
              }
              accept="image/jpeg"
            />
            {serlian.picture && (
              <button
                onClick={resetField}
                type="reset"
                className="cross-button reset-edit"
              ></button>
            )}
          </div>
        </label>
        <label className="form__control">
          <span>Lien présentation sur le blog</span>
          <input
            type="text"
            className="form__input"
            name="blog_article"
            onChange={handleChange}
            value={serlian.blog_article}
          />
        </label>
        <label className="form__control">
          <span>Lien linkedin</span>
          <input
            type="text"
            className="form__input"
            name="linkedin"
            onChange={handleChange}
            value={serlian.linkedin}
          />
        </label>
        <label className="form__control">
          <span>Lien github</span>
          <input
            type="text"
            className="form__input"
            name="github"
            onChange={handleChange}
            value={serlian.github}
          />
        </label>
        <label className="form__control">
          <span>Lien twitter</span>
          <input
            type="text"
            className="form__input"
            name="twitter"
            onChange={handleChange}
            value={serlian.twitter}
          />
        </label>
        <PrimaryButton
          disabled={errorMailFormat || errorPicture}
          id="btn-confirm"
          value={valueButton}
        />
      </form>
    </div>
  );
};

export default EditSerlian;
