import React from 'react';
import "./buttons.scss";

export const UserButton = ({user, displayMenu}) => (
    <button className="user-button">
        {user && <img src={user.picture} alt="photo" className="user-picture" id="user-picture" onClick={displayMenu}/>}
    </button>
);

export const LogOutButton = ({icon}) => (
    <a href="/.well-known/otoroshi/logout" className="deconnexion">
        <div className="link">
            {icon}
            Déconnexion
        </div>
    </a>
);


export const PrimaryButton = ({value, id, disabled, onClick}) => (
    <button onClick={onClick} type="submit" disabled={disabled} id={id} className="primary-button">{value}</button>
);
