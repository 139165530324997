import moment from "moment";
moment.locale("fr");

export const getLists = (callback) => {
  fetch(`/api/lists`, {
    method: "GET",
    credentials: "include",
    headers: { Accept: "application/json" },
  })
    .then((response) => response.json())
    .then((lists) => {
      callback && callback(lists);
    });
};

export const addList = (list, title, description, callback) => {
  fetch(`/api/lists`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      title: title,
      description: description,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log("API Response:", response);
      callback && callback(response);
    });
};

export const deleteList = (list, callback) => {
  fetch(`/api/lists`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: list.id }),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log("API Response DELETE request:", response);
      callback && callback(response);
    });
};

export const updateList = (title, description, id, callback) => {
  fetch(`/api/lists`, {
    method: "PUT",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      title: title,
      description: description,
      id: id,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log("API Response:", response);
      callback && callback(response);
    });
};
