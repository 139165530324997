export const getSerlians = (callback) => {
  fetch("/api/serlians", {
    method: "GET",
    credentials: "include", //on inclut les cookies
    headers: { Accept: "application/json" },
  })
    .then((r) => r.json())
    .then((serlians) => {
      callback && callback(serlians);
    });
};

export const getSerlian = (name) => {
  return fetch(`/api/serlians/${name}`, {
    method: "GET",
    credentials: "include", //on inclut les cookies
    headers: { Accept: "application/json" },
  }).then((r) => r.json());
};

export const fetchUpdateProfileInformation = (formData, isEdit, callback) => {
  fetch("/api/serlians/informations", {
    method: isEdit ? "PUT" : "POST",
    credentials: "include",
    body: formData,
  })
    .then((response) => response.json())
    .then((response) => {
      callback && callback(response);
    });
};

export const updateProfilPicture = (file, serlian, callback) => {
  const formData = new FormData();
  formData.append("picture", file);
  formData.append("serlian", JSON.stringify(serlian));
  fetch("/api/serlians/picture", {
    method: "PUT",
    credentials: "include", //on inclut les cookies
    body: formData,
  })
    .then((response) => response.json())
    .then((response) => {
      callback && callback(response);
    });
};

export const updateSkills = (skills, idSerlian, callback) => {
  fetch(`/api/serlians/updateSkills`, {
    method: "POST",
    credentials: "include", //on inclut les cookies
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      idSerlian: idSerlian,
      skills: skills,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      callback && callback(response);
    });
};
