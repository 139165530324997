import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import SerlianContext from "../context";
import EditSkill from "./EditSkill";
import {IoMdTrash} from "react-icons/io";
import {GoCheck} from "react-icons/go";
import {FiEdit3} from "react-icons/fi";
import {MdAdd} from "react-icons/md";
import Toast from "../components/toast/Toast";
import {getSkills} from "../utils/fetch/fetchSkills";
import {skillValidation, deleteSkill, updateOrAddSkill} from "../utils/fetch/fetchSkillsAdmin";
import "./GestionSkills.scss";

class GestionSkills extends Component {

    static contextType = SerlianContext;

    constructor(props) {
        super(props);
        this.state = {
            skills: [],
            valueButton: "Mettre à jour",
            newSkills: [],
            oldSkills: [],
            filter: "all",
            skill: {
                id_skill: "",
                skill: "",
                validation: true
            },
            clazz: "form-modal",
        };
    }

    componentDidMount() {
        this.getAllSkills();
    }

    componentWillUnmount() {
        clearTimeout(this.state.toastTimer);
    }

    getAllSkills = () => {
           getSkills((skills) => {
                    this.setState({
                    skills,
                    newSkills: this.getNewSkills(skills),
                    oldSkills: this.getOldSkills(skills)});
                });
    };

    getNewSkills = (skills) => {
        return skills.filter(elt => !elt.validation);
    };

    getOldSkills = (skills) => {
        return skills.filter(elt => elt.validation);
    };

    skillValidation = (id_skill) => {
        skillValidation(id_skill, (response) => {
            if(response.name  === "success") {
                this.getAllSkills();
                this.setState({toast: "SUCCESS"});
            }
            else {
                this.setState({toast: "ERROR"})
            }

            const toastTimer = setTimeout(function() {
                this.setState({toast: ""});
            }.bind(this), 8000);

            this.setState({toastTimer})
        })
    }

    deleteSkill = (id_skill) => {
        deleteSkill(id_skill, (response) => {
            if(response.name  === "success") {
                this.getAllSkills();
                this.setState({toast: "SUCCESSDEL"});
            }
            else {
                this.setState({toast: "ERRORDEL"})
            }

            const toastTimer = setTimeout(function() {
                this.setState({toast: ""});
            }.bind(this), 8000);

            this.setState({toastTimer})
        })
    }

    updateOrAddSkill = (update, skill, currentTarget) => {
        updateOrAddSkill(update, skill, (response) => {
            if (response.name === "success") {
                this.setState({toast: update ? "SUCCESSUPDATE" : "SUCCESSADD"});
                this.id = "";
                this.skill = "";
                this.validation = true;
                this.toggleModalEdit(currentTarget);
                this.setState({skill});
                this.getAllSkills();
            }
            else if(response.message === "EXIST") {
                this.setState({toast: "EXIST"});
            }
            else {
                this.setState({toast: update ? "ERRORUPDATE" : "ERRORADD" });
            }
        })
    }

    handleChange = (e) => {
        let target = e.currentTarget;
        const {skill} = this.state;
        let maxLen = this.context.maxLength;
        switch(target.name){
            case "filter":
                this.setState({ filter : target.value });
                break;
            case "name":
                if(target.value.length < maxLen) skill.skill = e.target.value;
                this.setState({skill});
                break;
            case "validation":
                skill.validation = e.target.checked;
                this.setState({skill});
                break;
            default:
                break;
        }
    };

    handleClick = (e, s) => {
    let value = e.currentTarget.value;
        if(value === "valid") {
            this.skillValidation(s.id_skill);
        }
        else if(value === "delete") {
            this.deleteSkill(s.id_skill);
        }
        else if(value === "add") {
            let {skill} = this.state;
            skill.id_skill = "";
            skill.skill = "";
            skill.validate = true;
            this.setState({clazz: "form-modal toggle-add", valueButton: "Ajouter", skill});
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({valueButton: <div className="loader"/>});
        const currentTarget = e.currentTarget;
        const {skill, valueButton} = this.state;
        const update = valueButton === "Mettre à jour";
        this.updateOrAddSkill(update, skill, currentTarget);
        this.setState({valueButton: "Envoyer"});
        const toastTimer = setTimeout(function() {
            this.setState({toast: ""});
        }.bind(this), 8000);
        this.setState({toastTimer})
    };

    toggleModalEdit = (target) => {
        if(target.classList.contains("toggle-add")) {
            this.setState({clazz: "form-modal"});
        }
    };

    renderToast = () => {
        switch(this.state.toast) {
            case "SUCCESS":
                return <Toast type="success"
                              title="Succès"
                              message="La compétence a bien été validée."/>;
            case "EXIST":
                return <Toast type="error"
                              title="Erreur"
                              message="La compétence existe déjà."/>;
            case "ERROR":
                return <Toast type="error"
                              title="Erreur"
                              message="La compétence n'a pas pu être validée, veuillez réessayer plus tard."/>;
            case "SUCCESSDEL":
                return <Toast type="success"
                              title="Succès"
                              message="La compétence a bien été supprimée."/>;
            case "ERRORDEL":
                return <Toast type="error"
                              title="Erreur"
                              message="La compétence n'a pas pu être supprimée, veuillez réessayer plus tard."/>;
            case "SUCCESSUPDATE":
                return <Toast type="success"
                              title="Succès"
                              message="La compétence a bien été modifiée."/>;
            case "ERRORUPDATE":
                return <Toast type="error"
                              title="Erreur"
                              message="La compétence n'a pas pu être modifiée, veuillez réessayer plus tard."/>;
            case "SUCCESSADD":
                return <Toast type="success"
                              title="Succès"
                              message="La compétence a bien été ajoutée."/>;
            case "ERRORADD":
                return <Toast type="error"
                              title="Erreur"
                              message="La compétence n'a pas pu être ajoutée, veuillez réessayer plus tard."/>;
            default:
                return null;
        }
    };

    render() {
        let {filter, skills, newSkills, oldSkills} = this.state;
        let skillsList = [];
        switch(filter) {
            case "all":
                skillsList = skills;
                break;
            case "new":
                skillsList = newSkills;
                break;
            case "old":
                skillsList = oldSkills;
                break;
            default:
                break;
        }
        if(this.context.serlianConnected.role !== "admin") {
            return (<Redirect to="/" />)
        }
        return (
             <div className="gestion-skills-container">
                {this.renderToast()}
                <h2> Gestion Compétences </h2>
                <div className="advice">
                    <span>Les compétences grisées sont des compétences à valider.</span>
                </div>
                <div className="addSkill">
                    <span>Ajouter une compétence</span>
                    <button type="button" className="btn-round" value="add" title="Ajouter une compétence" onClick = {(e) => this.handleClick(e)}>
                        <MdAdd style={{fontSize: "24px"}}/>
                     </button>
                 </div>
                <form>
                    <div className="form-control">
                        <select className="form-input" name="filter" value={this.state.filter}
                                onChange={this.handleChange}>
                            <option value="all"> Toutes les compétences ({this.state.skills.length})</option>
                            <option value="new"> Compétences à valider ({this.state.newSkills.length})</option>
                            <option value="old"> Compétences validées ({this.state.oldSkills.length})</option>
                        </select>
                    </div>
                    <table className="gestion-serlian" cellSpacing="0">
                        <thead>
                        <tr>
                            <th>Nom</th>
                            {
                            filter === "new" &&
                                    <th><GoCheck style={{fontSize: "24px"}}/></th>
                            }
                            <th><FiEdit3 style={{fontSize: "24px"}}/></th>
                            <th><IoMdTrash style={{fontSize: "24px"}}/></th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                                skillsList && skillsList.map((skill, index) => {
                                    return(
                                        <tr className={skill.validation ? "normal" : "toValidate"} key={index}>
                                            <td>{skill.skill}</td>
                                            {
                                                filter === "new" &&
                                                    <td>
                                                        <button type="button" className="btn-round" value="valid" title="Valider la compétence"
                                                            onClick = {(event) => this.handleClick(event, skill)}>
                                                            <GoCheck style={{fontSize: "24px"}}/>
                                                        </button>
                                                    </td>
                                            }
                                            <td>
                                                <button type="button" className="btn-round" title="Modifier la compétence" onClick={() => {
                                                    this.setState({clazz: "form-modal toggle-add", skill: {...skill}, valueButton: "Mettre à jour"})
                                                }}>
                                                    <FiEdit3/>
                                                </button>
                                            </td>
                                            <td>
                                                <button type="button" className="btn-round" value="delete" title="Supprimer la compétence"
                                                    onClick = {(event) => this.handleClick(event, skill)}>
                                                    <IoMdTrash style={{fontSize: "24px"}}/>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </form>
                <EditSkill
                    toggleModal={this.toggleModalEdit}
                    clazz={this.state.clazz}
                    skill={this.state.skill}
                    handleSubmit={this.handleSubmit}
                    handleChange={this.handleChange}
                    valueButton={this.state.valueButton}
                />
            </div>
        );
    }
}

export default GestionSkills;