import React, {Component} from "react";
import {PrimaryButton} from "../components/buttons/Buttons";
import {TiDelete} from "react-icons/ti";

class EditSerlien extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {serlian} = this.props;
        return (
            <form className={this.props.clazz} onClick={(e) => this.props.toggleModal(e.target)}
                  onSubmit={this.props.handleSubmit}>
                <div className="modal-add-container overflow-auto">
                    <div className="form-header">
                        <h3>Editer les informations</h3>
                        <em>Les champs suivis d'une étoile <u>doivent</u> être remplis</em>
                    </div>
                    <label className="form-control">
                        <span>Prénom *</span>
                        <input type="text" className="form-input" name="firstname"
                               onChange={this.props.handleChange}
                               value={serlian.firstname} required/>
                    </label>
                    <label className="form-control">
                        <span>Nom *</span>
                        <input type="text" className="form-input" name="lastname"
                               onChange={this.props.handleChange} value={serlian.lastname} required/>
                    </label>
                    <label className="form-control">
                        <span>Email *
                            {this.props.errorMailFormat &&
                            <em>Cette adresse mail n'est pas de type serli.com</em>}
                            {this.props.errorMailExist && <em>Cette adresse mail est déjà utilisée</em>}
                        </span>
                        <input type="email"
                               className={this.props.errorMailFormat || this.props.errorMailExist ? "form-input form-error" : "form-input"}
                               name="email" onChange={this.props.handleChange} value={serlian.email} required/>
                    </label>
                    <label className="form-control">
                        <span>Poste *</span>
                        <input type="text" className="form-input" name="job" onChange={this.props.handleChange}
                               value={serlian.job} required/>
                    </label>
                    <label className="form-control">
                        <span>Ville</span>
                        <input type="text" className="form-input" name="city" onChange={this.props.handleChange}
                               value={serlian.city} />
                    </label>
                    <label className="form-control form-picture">
                        <span>Photo {this.props.errorPicture &&
                            <em>L'image doit être de dimension carrées (ex. 500x500)</em>}
                        </span>
                        <input type="file" onChange={this.props.handleChange} key={this.props.inputFileKey}
                               className={this.props.errorPicture ? "form-input form-error" : "form-input"}
                               accept="image/jpeg" />
                        <button onClick={this.props.resetField} type="reset"
                                className={this.props.picture ? "crossButton reset-edit show" : "crossButton reset-edit"}>
                            <TiDelete/>
                        </button>
                    </label>
                    <PrimaryButton disabled={this.props.errorMailFormat || this.props.errorPicture} id="btn-confirm" value={this.props.valueButton} />
                </div>
            </form>
        );
    }
}

export default EditSerlien;