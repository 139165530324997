import React, {Component} from "react";
import TabBar from "../components/serlian/tabBar/TabBar";
import Informations from "../components/serlian/Informations";
import Missions from "../components/serlian/Missions";
import IsPending from "../components/isPending/IsPending";
import SerlianContext from "../context";
import Toast from "../components/toast/Toast";
import {FaAt} from "react-icons/fa";
import {MdLocationOn} from "react-icons/md";
import { TiArrowMaximise } from "react-icons/ti";
import {getSerlian, updateProfilPicture} from "../utils/fetch/fetchUser";
import "./profil.scss";
import {LOADING} from "../utils/Images";

class SerlianProfil extends Component {

    _isMounted = false;

    static contextType = SerlianContext;

    constructor(props) {
        super(props);
        this.state = {
            tab: "",
            isPending: true,
            isEditable: false,
            ascending: false,
            scrollDirection: "top",
            serlianNotFound: false
        };
        this.lastY = 0;
    }

    componentDidMount() {
        this.getSerlian();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.params.name !== this.props.match.params.name) {
            this.getSerlian();
        }

        if(!this.state.isEditable && this.state.serlian && this.context.serlianConnected.email === this.state.serlian.email) {
            this.setState({isEditable: true});
        }
        else if(this.state.isEditable && this.state.serlian && this.context.serlianConnected.email !== this.state.serlian.email) {
            this.setState({isEditable: false});
        }
    }

    componentWillUnmount() {
        clearTimeout(this.state.toastTimer);
    }

    getSerlian = () => {
        this._isMounted = true;
        getSerlian(this.props.match.params.name.toLocaleLowerCase())
            .then(serlian => {
                if (this._isMounted) {
                    if (serlian.message) {
                        this.setState({serlianNotFound: true, isPending: false})
                    }
                    if ((this.state.serlian && this.state.serlian.email !== serlian.email) || !this.state.serlian) {
                        this.setState({tab: "Informations"})
                    }
                    this.setState({serlian, isPending: false, updateSerlian: false});
                    this._isMounted = false;
                }
            });
    }

    // Permet de modifier la photo de profil d'un serlien à partir de son profil en mode admin uniquement
    updateProfilPicture = (e) => {
        const {serlian} = this.state;
        serlian.picture = LOADING;
        this.setState(serlian);
        const self = this;
        let file = e.target.files[0];
        let img = new Image();
        img.onload = function() {
            if (this.width !== this.height) {
                self.setState({toast: "ERROR_PICTURE_DIM"})
            }
            else {
                serlian.picture = this.src;
                serlian.haspicture = true;
                updateProfilPicture(file, serlian, (response) => {
                    if (response.name === "success") {
                        this.setState({serlian, toast: "SUCCESS"});
                    }
                    else {
                        this.setState({toast: "ERROR"})
                    }
                });
            }
        };
        img.onerror = function () {
            alert("not a valid file: " + file.type);
        };
        img.src = window.URL.createObjectURL(file);
        const toastTimer = setTimeout(function () {
            this.setState({toast: ""});
        }.bind(this), 8000);
        this.setState({toastTimer});
    };


    // Permet de réduire l'image du serlien en scrollant son profil
    handleScroll = (e) => {
        let y = e.target.scrollTop;

        if(y > 0) {
            if (y >= 25 && !this.state.ascending) {
                this.setState({ascending: true, scrollDirection: "bottom"});
                this.lastY = y;
            } else if (e.target.scrollHeight > window.innerHeight - 108) {
                if (y - this.lastY < 0 && this.state.ascending) {
                    this.setState({scrollDirection: "top"})
                }
            }
        }
        else if(y === 0 && this.state.scrollDirection === "bottom") {
            e.target.scrollTop = 2;
        }
        else if(y === 0 && this.state.scrollDirection === "top") {
            this.lastY = y;
            this.setState({ascending: false});
        }
    };

    displayTab = (value) => {
        this.setState({tab: value, ascending: false});
    };

    renderToast = () => {
        switch(this.state.toast) {
            case "SUCCESS":
                return <Toast type="success"
                              title="Succès"
                              message="L'image a bien été modifiée." />;
            case "ERROR_PICTURE_DIM":
                return <Toast type="error"
                              title="Erreur"
                              message="L'image doit être de dimension carrée (ex. 500x500)" />;
            default:
                return null;
        }
    };

    // Permet d'afficher l'un ou l'autre composant en version mobile
    renderTab = () => {
        const {serlian} = this.state;
        switch(this.state.tab) {
            case "Informations":
                return <Informations
                            serlian={serlian}
                            idSerlian={this.state.serlian.id}
                            isEditable={this.state.isEditable}/>;
            case "Missions":
                return <Missions idSerlian={this.state.serlian.id}/>;
            default:
                return null;
        }
    };

    render() {
        const {serlian} = this.state;
        const screen = this.context.isMobile;
        return (
            this.state.isPending ?
                <IsPending/>
            :
                this.state.serlianNotFound ?
                    <div className="error-page">
                        <h2>Oups...</h2>
                        <p>Il semble que le Serlien demandé n'existe pas.</p>
                    </div>
                :
                    <div className="profil-container">
                        {this.renderToast()}
                        <div className="profil" id="cont" onScroll={this.handleScroll}>
                            {
                                serlian &&
                                screen ?
                                    <>
                                        <div className="identity-card-container">
                                            <div className={"identity-card" + (this.state.ascending ? " reduce" : "")}>
                                                <div className={"picture" + (this.context.serlianConnected.role === "admin" ? " picture-edit": "")}>
                                                    <div className="roundedImage">
                                                        <img className="profil-picture" src={serlian.picture} alt="photo"/>
                                                        {
                                                            this.context.serlianConnected.role === "admin" &&
                                                                <div className="middle" onClick={() => this.FileUpdate.click()}>
                                                                    <p className="text"><strong>Modifier l'image</strong></p>
                                                                </div>
                                                        }
                                                    </div>
                                                    {
                                                        this.context.serlianConnected.role === "admin" &&
                                                        <input
                                                            type="file"
                                                            accept="image/jpeg"
                                                            hidden
                                                            ref={(file) => this.FileUpdate = file}
                                                            onChange={this.updateProfilPicture}
                                                        />
                                                    }
                                                </div>
                                                <div className="presentation">
                                                    <h2>{serlian.firstname} {serlian.lastname}</h2>
                                                    <p className="job">{serlian.role.substr(0, 1).toUpperCase() + serlian.role.substring(1).toLowerCase()}</p>
                                                </div>
                                                <div className="infos-serlian">
                                                    <div className="icone">
                                                        <FaAt className="icons"/>
                                                        <p> {serlian.email}</p>
                                                    </div>
                                                    {
                                                        serlian.city &&
                                                            <div className="icone">
                                                                <MdLocationOn className="icons"/>
                                                                <p className="city">{serlian.city}</p>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                this.state.ascending &&
                                                    <button className="btn-down" title="Revenir en haut" onClick={() => {
                                                        document.getElementById("cont").scrollTop = 0;
                                                        this.setState({ascending: false})
                                                    }}>
                                                        <TiArrowMaximise/>
                                                    </button>
                                            }
                                        </div>
                                        {this.renderTab()}
                                    </>
                                :
                                    <>
                                        <Informations
                                            serlian={serlian}
                                            idSerlian={this.state.serlian.id}
                                            isEditable={this.state.isEditable}
                                            updateProfilPicture={this.updateProfilPicture} />
                                        <Missions idSerlian={this.state.serlian.id} />
                                    </>
                            }
                        </div>
                        {
                            screen &&
                            <TabBar display={this.displayTab} idSerlian={this.state.serlian.id}/>
                        }
                    </div>
        );
    }
}

export default SerlianProfil;
