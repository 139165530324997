import React, { Component } from "react";
// import { withRouter } from "react-router-dom";
import { PrimaryButton } from "../components/Buttons";
import Toast from "../components/Toast";
import { sendIssue } from "../utils/fetch/fetchGlobal";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "question",
      title: "",
      message: "",
      toast: "",
      valueButton: "Envoyer",
      disabledButton: false,
      toastTimer: null,
    };
  }

  componentWillUnmount() {
    clearTimeout(this.state.toastTimer);
  }

  handleChange = (e) => {
    this.setState({ [e.currentTarget.name]: e.currentTarget.value });
  };

  sendIssue = (form) => {
    sendIssue(
      form,
      this.state.title,
      this.state.type,
      this.state.message,
      this.props.serlianConnected.email,
      (response) => {
        if (response.message === "success") {
          this.setState({ toast: "SUCCESS" });
          form.reset();

          const toastTimer = setTimeout(() => {
            this.setState({ toast: "" });
          }, 2000);

          setTimeout(() => {
            this.props.history.push("/");
          }, 3000);
        } else {
          this.setState({ toast: "ERROR" });
        }

        this.setState({ valueButton: "Envoyer", disabledButton: false });
        const toastTimer = setTimeout(
          function () {
            this.setState({ toast: "" });
          }.bind(this),
          8000
        );
        this.setState({ toastTimer });
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      valueButton: <div className="loader" />,
      disabledButton: true,
    });
    const form = e.currentTarget;
    this.sendIssue(form);
  };

  renderToast = () => {
    switch (this.state.toast) {
      case "SUCCESS":
        return (
          <Toast
            type="success"
            title="Succès"
            message="Votre demande à bien été envoyée, nous la traiterons dans les plus bref délais."
          />
        );
      case "ERROR":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="Votre demande n'a pas pu être envoyée, veuillez réessayer plus tard."
          />
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <div className="container container--column">
        {this.renderToast()}
        <h1>Contactez-nous</h1>
        <img
          src="/images/contact-serli-mail.png"
          className="img--contact"
          alt="contact"
        />
        <form onSubmit={this.handleSubmit} id="form-contact" className="form">
          <label className="form__control">
            <span>Je souhaite</span>
            <select
              name="type"
              className="form__input"
              onChange={this.handleChange}
            >
              <option value="question">Demander une information</option>
              <option value="bug">Signaler un bug</option>
              <option value="idée">Soumettre une idée</option>
            </select>
          </label>

          <label className="form__control">
            <span>Titre</span>
            <input
              type="text"
              name="title"
              className="form__input"
              onChange={this.handleChange}
              required
            />
          </label>

          <label className="form__control">
            <span>Votre message</span>
            <textarea
              name="message"
              className="form__input"
              onChange={this.handleChange}
              required
            />
          </label>
          <PrimaryButton
            disabled={this.state.disabledButton}
            id="btn-confirm"
            value={this.state.valueButton}
          />
        </form>
      </div>
    );
  }
}

export default Contact;
