import React, {Component} from 'react';
import Serlians from "../components/Serlians";

class Home extends Component {
    render() {
        return (
            <Serlians/>
        );
    }
}

export default  Home;
