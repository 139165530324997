import React, { Component } from 'react';
import { DragSource, DropTarget } from 'react-dnd';

const dragSource = DragSource("item", {
    beginDrag(props){
        return {
            id: props.id,
            name: props.name,
            originalIndex: props.findItem(props.id)
        };
    },

    endDrag(props = CardProps, monitor = DragSourceMonitor) {
        const { id, originalIndex } = monitor.getItem();
        const didDrop = monitor.didDrop();

        if(!didDrop) {
            props.onDrop(id, originalIndex)
        }
    },
}, (connect, monitor) => {
    return {
        connectDragSource: connect.dragSource(),
        // connectDragPreview: connect.DragPreview(),
        isDragging: monitor.isDragging()
    };
})

const droptTarget = DropTarget("item", {
    canDrop() {
        return true;
    },

    hover(props , monitor){
        const draggedId = monitor.getItem().id;
        const overId = props.id;

        if(draggedId !== overId){
            const overIndex = props.findItem(overId);
            props.onDrop(draggedId, overIndex);
        }
    },
}, connect => {
    return {
        connectDropTarget: connect.dropTarget()
    };
})

class DragItem extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const { isDragging } = this.props;
        return this.props.connectDragSource(
            this.props.connectDropTarget(
                <div className="div-drag" style={{opacity: isDragging ? 0 : 1}}>

                    <span className="skill drag">{this.props.name}</span>
                </div>
            )
        )
    }
}

export default dragSource(droptTarget(DragItem));