import React, { Component } from "react";
import { MdAdd } from "react-icons/md";
import { MdPanoramaFishEye } from "react-icons/md";
import { FiEdit3 } from "react-icons/fi";
import SerlianContext from "../../../context";
import AddSkill from "./AddSkill";
import { PrimaryButton } from "../../Buttons";
import Toast from "../../Toast";
import SortableList from "../../dnd/SortableList";
import { updateSkills } from "../../../utils/fetch/fetchUser";
import {
  getUserSkills,
  getSkills,
  removeSkill,
} from "../../../utils/fetch/fetchSkills";

class Skills extends Component {
  static contextType = SerlianContext;

  constructor(props) {
    super(props);
    this.state = {
      clazz: "form--modal",
      isEdited: false,
      change: false,
      skillsToDelete: [],
      toast: "",
      skillValidate: false,
      skillInvalidate: false,
      allSkills: [],
      skills: [],
      orderSkills: [],
      idSerlian: this.props.idSerlian,
    };
  }

  componentDidMount() {
    this.getAllSkills();
  }

  getUserSkills = () => {
    if (this.state.skills) return this.state.skills;
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    return prevProps;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.idSerlian !== this.state.idSerlian) {
      this.setState({ idSerlian: this.props.idSerlian });
      this.getAllSkills();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.toastTimer);
  }

  updateSkills = () => {
    let skills = this.selectIdSkills(this.state.skills);
    updateSkills(skills, this.props.idSerlian);
  };

  selectSkills = (idSkills) => {
    let skillValidate = false;
    let skillInvalidate = false;
    let skills = [];
    let skill;
    if (idSkills !== null && idSkills.length > 0) {
      idSkills.map((idSkill) => {
        skill = this.state.allSkills.filter(
          (skill) => idSkill === skill.id_skill
        )[0];
        if (skill && skill.validation) {
          skillValidate = true;
        } else if (skill && !skill.validation) {
          skillInvalidate = true;
        }
        skill && skills.push(skill);
      });
    }
    this.setState({ skills, skillValidate, skillInvalidate });
  };

  getSkills = () => {
    getUserSkills(this.props.idSerlian, (skills) => {
      this.selectSkills(skills);
    });
  };

  selectIdSkills = (skills) => {
    let ids = [];
    skills.map((skill) => {
      ids.push(skill.id_skill);
    });
    return ids;
  };

  getAllSkills = () => {
    getSkills((skills) => {
      this.setState({ allSkills: skills }, () => this.getSkills());
    });
  };

  removeSkill = (skill) => {
    removeSkill(
      this.selectIdSkills([skill]),
      this.props.idSerlian,
      (response) => {
        if (response.name === "success") {
          this.getSkills();
          this.setState({ toast: "SUCCESS" });
        } else if (response.message === "NOT_ALLOWED") {
          this.setState({
            toast: "NOT_ALLOWED",
            valueButton: "Envoyer",
            disabledButton: false,
          });
        } else {
          this.setState({ toast: "ERROR" });
        }

        const toastTimer = setTimeout(
          function () {
            this.setState({ toast: "" });
          }.bind(this),
          8000
        );

        this.setState({ toastTimer });
      }
    );
  };

  toggleModalAdd = (target) => {
    if (target.classList.contains("toggle-add")) {
      this.setState({ clazz: "form--modal" });
    }
  };

  handleChangeCheckBox = (e, index) => {
    const { skillsToDelete } = this.state;

    if (e.currentTarget.checked) {
      if (skillsToDelete.indexOf(this.state.skills[index]) === -1) {
        skillsToDelete.push(this.state.skills[index]);
      }
    } else {
      skillsToDelete.splice(
        skillsToDelete.indexOf(this.state.skills[index]),
        1
      );
    }
    this.setState({ skillsToDelete });
  };

  handleClick = (event) => {
    let target = event.currentTarget;
    if (target.name === "change") {
      this.setState({
        change: !this.state.change,
        orderSkills: [],
        isEdited: !this.state.isEdited,
      });
    } else if (target.id === "btn-confirm") {
      let skills =
        this.state.orderSkills.length > 0
          ? this.state.orderSkills
          : this.state.skills;
      this.setState(
        { skills, orderSkills: [], change: false, isEdited: false },
        () => this.updateSkills()
      );
    }
  };

  renderToast = () => {
    switch (this.state.toast) {
      case "SUCCESS":
        return (
          <Toast
            type="success"
            title="Succès"
            message="La compétence a bien été supprimée."
          />
        );
      case "NOT_ALLOWED":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="Vous n'êtes pas autorisé à faire ceci."
          />
        );
      case "ERROR":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="La compétence n'a pas pu être supprimée, veuillez réessayer plus tard."
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { skills } = this.state;

    return (
      <div className="section section--skills">
        {this.renderToast()}
        <div className="section__header">
          <h3 className="section__header__title">Compétences</h3>
          {(skills || this.props.isEditable) && (
            <>
              {this.props.isEditable && (
                <button
                  className="btn btn--round"
                  title="Ajouter une compétence"
                  onClick={() =>
                    this.setState({ clazz: "form--modal toggle-add" })
                  }
                >
                  <MdAdd />
                </button>
              )}
              {skills.length > 0 &&
                this.state.skillValidate &&
                this.props.isEditable && (
                  <button
                    className={
                      "btn btn--round btn" +
                      (this.state.isEdited ? " edit" : "")
                    }
                    name="change"
                    title="Modifier les compétences"
                    onClick={this.handleClick}
                  >
                    <FiEdit3 />
                  </button>
                )}
            </>
          )}
        </div>
        {this.state.change && (
          <span className="advice">
            Faites glisser vos compétences pour les organiser comme vous le
            voulez.
          </span>
        )}
        <div className="card card--skills">
          {this.state.change ? (
            <SortableList
              idSerlian={this.props.idSerlian}
              handleClickDelete={this.removeSkill}
              skills={this.state.skills}
              updateOrder={(orderSkills) => this.setState({ orderSkills })}
            />
          ) : this.state.skills &&
            skills.length > 0 &&
            this.state.skillValidate ? (
            skills.map((skill, index) => {
              return (
                skill.validation && (
                  <label className="div__skill" key={index} data-key={index}>
                    <span className="skill">{skill.skill}</span>
                  </label>
                )
              );
            })
          ) : this.props.isEditable ? (
            <div className="container">
              <i className="defaultPhrase">
                Complétez cette partie avec vos compétences.
              </i>
            </div>
          ) : (
            <div className="container">
              <i className="defaultPhrase">
                Les compétences n’ont pas encore été renseignées.
              </i>
            </div>
          )}
          {this.props.isEditable && (
            <AddSkill
              clazz={this.state.clazz}
              toggleModal={this.toggleModalAdd}
              skills={this.state.allSkills}
              serlian={this.context.serlianConnected}
              updateSkills={this.getAllSkills}
              currentSkills={this.state.skills}
            />
          )}
          {this.props.isEditable && this.state.skillInvalidate && (
            <div className="div__skills-to-validate">
              <h4 className="div__skills-to-validate__title">
                Compétences en attente de validation
              </h4>
              <div className="div__skills-to-validate__content">
                {skills.map((skill, index) => {
                  return (
                    !skill.validation && (
                      <span key={index} className="skill">
                        {skill.skill} <span className="divider">, </span>
                      </span>
                    )
                  );
                })}
              </div>
            </div>
          )}
          {this.state.isEdited && this.state.skillsToDelete.length > 0 && (
            <PrimaryButton
              onClick={this.handleClickDelete}
              id="btn-confirm"
              value="Supprimer"
              label="Supprimer les compétences selectionées"
            />
          )}
          {this.state.change && (
            <PrimaryButton
              onClick={this.handleClick}
              id="btn-confirm"
              value="Valider"
              label="Valider le changement"
            />
          )}
        </div>
      </div>
    );
  }
}

export default Skills;
