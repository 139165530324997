export const SERLIAN_DEFAULT_PICTURE = "/images/SuperSerli.png";
export const SERLIAN_TURNED_CARD_PICTURE = "/images/SuperSerli.png";

export function getImageIfValid(img) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve(img);
    };
    image.onerror = () => {
      reject({ error: "not found" });
    };
    image.src = img;
  });
}

export const LOADING = "/images/loading.gif";
