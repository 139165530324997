import React, {Component} from "react";
import {PrimaryButton} from "../components/buttons/Buttons";

class EditSkill extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {skill} = this.props;
        return (
            <form className={this.props.clazz} onClick={(e) => this.props.toggleModal(e.target)}
                  onSubmit={this.props.handleSubmit}>
                <div className="modal-add-container overflow-auto">
                    <div className="form-header">
                        <h3>Editer la compétence</h3>
                    </div>
                    <label className="form-control">
                        <span>Nom</span>
                        <input type="text" className="form-input" name="name"
                               onChange={this.props.handleChange}
                               value={skill.skill} required/>
                    </label>
                    <label className="form-control">
                        <span>Validée</span>
                        <em>Cochez cette case pour valider la compétence</em>
                        <input type="checkbox" name="validation"
                               onChange={this.props.handleChange} checked={skill.validation}/>
                    </label>
                    <PrimaryButton id="btn-confirm" value={this.props.valueButton} />
                </div>
            </form>
        );
    }
}

export default EditSkill;