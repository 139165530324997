export const addOrUpdateSerlian = (formData, isEdit, callback) => {

    fetch("/api/serlians", {
        method: isEdit ? "PUT" : "POST",
        credentials: "include", //on inclut les cookies
        body: formData
    })
        .then(response => response.json())
        .then(response => {
            callback && callback(response);
        });
};

export const deleteSerlian = (serlian, callback) => {
    fetch("/api/serlians", {
        method: "DELETE",
        credentials: "include", //on inclut les cookies
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify({serlian})
    })
        .then(response => response.json())
        .then(response => {
            callback && callback(response);
        });
};

export const archiveSerlian = (serlian, callback) => {
    serlian.archive = !serlian.archive;
    fetch("/api/serlians/archive", {
        method: "PUT",
        credentials: "include", //on inclut les cookies
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify({serlian})
    })
        .then(response => response.json())
        .then(response => {
            callback && callback(response);
        });
};