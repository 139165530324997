import React, {Component} from "react";
import {FaMicrophoneAlt} from "react-icons/fa";
import {MdAdd} from "react-icons/md";
import {FiEdit3} from "react-icons/fi";
import {IoMdTrash} from "react-icons/io";
import moment from "moment";
import "./conferences.scss";
import AddConference from "./AddConference";
import Toast from "../../toast/Toast";
import {getConferences, addConference, deleteConference, updateConference} from "../../../utils/fetch/fetchConference";

moment.locale("fr");

class Conferences extends Component {

    constructor(props) {
        super(props);

        this.state = {
            conferences: [],
            clazz: "form-modal",
            editing: false,
            conf: {
                place: "",
                title: "",
                year: moment().format('YYYY'),
                video_link: "",
                article_link: ""
            },
            idSerlian: this.props.idSerlian
        }
    }

    componentDidMount() {
        this.getConferences();
    }

    getConferences = () => {
        getConferences(this.props.idSerlian, (conferences) => {
            this.setState({conferences});
        })
    };

    addConference = (conference) => {
        addConference(conference, this.props.idSerlian, (response) => {
            if(response.name === "success"){
                this.setState({clazz: "form-modal", toast:"SUCCESSADD"});
            } else {
                this.setState({clazz: "form-modal", toast:"ERRORADD"})
            }

            this.getConferences();
            const toastTimer = setTimeout(function () {
                this.setState({toast: ""});
            }.bind(this), 8000);
            this.setState({toastTimer});
        })
    };

    deleteConference = (conference) => {
        deleteConference(conference, (response) => {
            if(response.name === "success"){
                this.setState({toast: "SUCCESSDEL"});
            } else {
                this.setState({toast: "ERRORDEL"});
            }
            this.getConferences();
        })
    };

    updateConference = (conference) => {
        updateConference(conference, (response) => {
            if(response.name === "success"){
                this.setState({clazz: "form-modal", toast:"SUCCESSUPDATE"});
            } else {
                this.setState({clazz: "form-modal", toast:"ERRORUPDATE"})
            }

            this.getConferences();
            const toastTimer = setTimeout(function () {
                this.setState({toast: ""});
            }.bind(this), 8000);
            this.setState({toastTimer});
        })
    };

    componentDidUpdate() {
        if(this.props.idSerlian !== this.state.idSerlian){
            this.setState({idSerlian: this.props.idSerlian}, () => this.getConferences());
        }
    }

    toggleModalAdd = (target) => {
        if(target.classList.contains("toggle-add")) {
            this.setState({clazz: "form-modal"});
        }
    };

    handleAdd = () => {
        let conf = {
            place: "",
            title: "",
            year: moment().format('YYYY'),
            video_link: "",
            article_link: ""
        };
        this.setState({clazz: "form-modal toggle-add", editing: false, conf});
    };

    handleEdit = (conf) => {
        conf.year = moment(conf.year).format('YYYY');
        this.setState({conf, clazz: "form-modal toggle-add"});
    };

    renderToast = () => {
        switch (this.state.toast) {
            case "SUCCESSADD":
                return <Toast type="success"
                              title="Succès"
                              message={"La conférence a bien été ajoutée."}/>;
            case "ERRORADD" :
                return <Toast type="error"
                              title="Erreur"
                              message="La conférence n'a pas pu être ajoutée, veuillez réessayer plus tard."/>;
            case "SUCCESSDEL":
                return <Toast type="success"
                              title="Succès"
                              message={"La conférence a bien été supprimée."}/>;
            case "ERRORDEL" :
                return <Toast type="error"
                              title="Erreur"
                              message="La conférence n'a pas pu être supprimée, veuillez réessayer plus tard."/>;
            case "SUCCESSDUPDATE":
                return <Toast type="success"
                              title="Succès"
                              message={"La conférence a bien été mise à jour."}/>;
            case "ERRORUPDATE" :
                return <Toast type="error"
                              title="Erreur"
                              message="La conférence n'a pas pu être mise à jour, veuillez réessayer plus tard."/>;
        }
    };

    render() {
        const {conferences} = this.state;
        return (
            ((conferences && conferences.length > 0) || this.props.isEditable) &&
                <div className="conferences-container">
                    {this.renderToast()}
                    <div className="conferences-header">
                        <h3 className="conferences-title"><FaMicrophoneAlt/> Conférences</h3>
                        {
                            this.props.isEditable &&
                                <button className="btn"
                                        type="button"
                                        title="Ajouter une conférence"
                                        onClick={this.handleAdd}>
                                    <MdAdd/>
                                </button>
                        }
                        {
                            (this.props.isEditable && conferences.length > 0) &&
                            <button className="btn"
                                    type="button"
                                    title="Modifier les conférences"
                                    onClick={() => this.setState({editing: !this.state.editing})}>
                                <FiEdit3/>
                            </button>
                        }
                    </div>
                    <div className="conferences">
                        {
                            conferences && conferences.length > 0 ?
                                conferences.map((conf, index) => {
                                    return (
                                        <div className="conference" key={index}>
                                            {
                                                this.state.editing &&
                                                <div className="buttons">
                                                    <button className="btn"
                                                            type="button"
                                                            title="Supprimer la conférence"
                                                            onClick={() => this.deleteConference(conf)}>
                                                        <IoMdTrash/>
                                                    </button>
                                                    <button className="btn"
                                                            type="button"
                                                            title="Modifier les conférences"
                                                            onClick={() => this.handleEdit(conf)}>
                                                        <FiEdit3/>
                                                    </button>
                                                </div>
                                            }
                                            <div className="year">
                                                <span>{moment(conf.year).format('YYYY')}</span>
                                            </div>
                                            <div className="description">
                                                <div className="conf-header">
                                                    <span className="place">{conf.place} : </span>
                                                    <span className="title">{conf.title}</span>
                                                </div>
                                                {
                                                    (conf.video_link || conf.article_link) &&
                                                        <div className="conf-link">
                                                            {
                                                                conf.video_link &&
                                                                <a target="_blank" rel="noopener noreferrer" href={conf.video_link}>Voir la vidéo</a>
                                                            }
                                                            {
                                                                conf.article_link &&
                                                                <a target="_blank" rel="noopener noreferrer" href={conf.article_link}>Voir l'article</a>
                                                            }
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    );
                                })
                            :
                                this.props.isEditable &&
                                <i className="defaultPhrase">Vous êtes speaker ? Renseignez ici vos conférences.</i>
                        }
                    </div>
                    {
                        this.props.isEditable &&
                            <AddConference clazz={this.state.clazz}
                                           toggleModal={this.toggleModalAdd}
                                           actualYear={moment().format('YYYY')}
                                           addConference={this.addConference}
                                           updateConference={this.updateConference}
                                           editing={this.state.editing}
                                           conf={this.state.conf}
                            />
                    }
                </div>
        );
    }
}

export default Conferences;