export const getMissions = (callback) => {
    fetch(`/api/missions`, {
        method: 'GET',
        credentials: 'include', //on inclut les cookies
        headers: {"Accept": "application/json"}
    })
        .then(response => response.json())
        .then(missions => {
            callback && callback(missions);
        });
};

export const getUserMissions = (idSerlian, callback) => {
    fetch(`/api/missions/${idSerlian}`, {
        method: 'GET',
        credentials: 'include', //on inclut les cookies
        headers: {"Accept": "application/json"}
    })
        .then(response => response.json())
        .then(missions => {
            callback && callback(missions);
        });
};