import React, { Component } from 'react';
import DragItem from './DragItem';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import CustomDragLayer from './CustomDragLayer';
import {IoMdTrash} from "react-icons/io";

class SortableList extends Component {
    constructor(props){
        super(props);
        this.state = {
            skills : this.props.skills
        }
    }

    componentDidUpdate() {
        if(this.state.skills !== this.props.skills){
            this.setState({skills: this.props.skills});
        }
    }

    findItem = (index) => {
        return this.state.skills.filter(s => s.id_skill === index)[0].id_skill;
    }

    handleDrop = (toId, fromId) => {
        const skills = this.state.skills;
        const toIndex = skills.findIndex(i => i.id_skill === toId);
        const fromIndex = skills.findIndex(i => i.id_skill === fromId);
        const toSkill = skills[toIndex];
        const fromSkill = skills[fromIndex];
        skills[toIndex] = fromSkill;
        skills[fromIndex] = toSkill;
        this.setState({skills})
        this.props.updateOrder(skills);
    }

    render(){
        return(
            <DndProvider backend={(isAndroid() || isIOS()) ? TouchBackend : HTML5Backend}>
                {(isAndroid() || isIOS()) && <CustomDragLayer/>}
                {
                    this.state.skills && this.state.skills.map((skill, index) => {
                        return (
                            skill.validation &&
                                 <div className="div-skill" key={index} data-key={index}>
                                        <button className={"btn" + (this.state.isEdited ? " edit" : "")}
                                                title="Supprimer la compétence"
                                                onClick={() => this.props.handleClickDelete(skill)}>
                                            <IoMdTrash/>
                                        </button>
                                        <DragItem key={skill.id_skill}
                                                  id={skill.id_skill}
                                                  onDrop = {(toId, fromId) => this.handleDrop(toId,fromId)}
                                                  name={skill.skill}
                                                  findItem={this.findItem}
                                        />
                                    </div>
                        )
                    })
                }
            </DndProvider>
        )
    }
}

function isAndroid() {
    return !!window.navigator.userAgent.match(/Android/);
}

function isIOS() {
    return !!window.navigator.userAgent.match(/iPhone|iPad|iPod/);
}

export default SortableList;
