export const updateSerlian = (formData, callback) => {
  fetch("/api/serlians", {
    method: "PUT",
    credentials: "include",
    body: formData,
  })
    .then((response) => response.json())
    .then((response) => {
      callback && callback(response);
    })
    .catch((error) => {
      console.error("Error in updateSerlian:", error);
    });
};

export const addSerlian = (formData, callback) => {
  fetch("/api/serlians", {
    method: "POST",
    credentials: "include",
    body: formData,
  })
    .then((response) => response.json())
    .then((response) => {
      callback && callback(response);
    })
    .catch((error) => {
      console.error("Error in addSerlian:", error);
    });
};

export const deleteSerlian = (serlian, callback) => {
  fetch("/api/serlians", {
    method: "DELETE",
    credentials: "include", //on inclut les cookies
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify({ serlian }),
  })
    .then((response) => response.json())
    .then((response) => {
      callback && callback(response);
    });
};

export const archiveSerlian = (serlian, callback) => {
  serlian.archive = !serlian.archive;
  fetch("/api/serlians/archive", {
    method: "PUT",
    credentials: "include", //on inclut les cookies
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify({ serlian }),
  })
    .then((response) => response.json())
    .then((response) => {
      callback && callback(response);
    });
};
