import React from "react";
import { FaSpinner } from "react-icons/fa";

const IsPending = () => {
  return (
    <div className="is-pending">
      <FaSpinner />
    </div>
  );
};

export default IsPending;
