export const getMissions = async (callback) => {
  try {
    const response = await fetch(`/api/missions`, {
      method: "GET",
      credentials: "include", // on inclut les cookies
      headers: { Accept: "application/json" },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const missions = await response.json();
    callback && callback(missions);
  } catch (error) {
    console.error("Error fetching missions:", error);
  }
};

export const getUserMissions = (idSerlian, callback) => {
  fetch(`/api/missions/${idSerlian}`, {
    method: "GET",
    credentials: "include", //on inclut les cookies
    headers: { Accept: "application/json" },
  })
    .then((response) => response.json())
    .then((missions) => {
      callback && callback(missions);
    });
};
