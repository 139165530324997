import React, {Component} from "react";
import {MdAccountCircle, MdAssignment} from "react-icons/md";
import "./tabBar.scss";

class TabBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            buttonInfo: "active",
            buttonClient: ""
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.idSerlian !== this.props.idSerlian) {
            this.setState({buttonInfo: "active", buttonClient: ""})
        }
    }

    handleClick = (e) => {
       if(e.currentTarget.classList.length === 0) {
           if(e.currentTarget.value === "Informations") {
               this.setState({buttonInfo: "active", buttonClient: ""})
           }
           else {
               this.setState({buttonInfo: "", buttonClient: "active"})
           }
       }
       this.props.display(e.currentTarget.value);
    };

    render() {
        return (
            <div className="tabBar">
                <div className="tabBar-button">
                    <button className={this.state.buttonInfo} onClick={this.handleClick} value="Informations"><MdAccountCircle /></button>
                    <button className={this.state.buttonClient} onClick={this.handleClick} value="Missions"><MdAssignment /></button>
                </div>
            </div>
        );
    }
}

export default TabBar;