import React from 'react';
import { getImageIfValid, SERLIAN_DEFAULT_PICTURE } from "../utils/Images";
import {Link} from "react-router-dom";

class SerlianCard extends React.Component {

    constructor(props){
        super(props);
        this.state = { picture : SERLIAN_DEFAULT_PICTURE };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getImage();
    }
    componentDidUpdate() {
        this.getImage()
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    getImage = () => {
        const img = this.props.serlian.picture;
        if (img !== SERLIAN_DEFAULT_PICTURE && img !== this.state.picture) {
            getImageIfValid(img).then((picture) => {
                if(this._isMounted){
                    this.setState({picture})
                }
            });
        }
    };

    getFirstAndLastName = () => {
        let mail = this.props.serlian.email;
        let split = mail.split("@");
        return split[0];
    }

    render() {
        const {serlian} = this.props;
        return (
            <Link to={{pathname: `/serliens/${this.getFirstAndLastName()}`}} className="border_item" >
                <img className="img" src={this.state.picture} alt="photo"/>
                <p>
                    <span>{serlian.firstname} {serlian.lastname}</span>
                </p>
            </Link>
        )
    }
}

export default SerlianCard;
