import React from "react";
import SerlianCard from "./SerlianCard";
import SerlianContext from "../context";
import SearchSerlian from "./searchBar/SearchSerlian";

class Serlians extends React.Component {

    static contextType = SerlianContext;

    constructor(props) {
        super(props);
        this.state = {
            sort: 1,
            serlians: [],
            init: -1,
            insertItem: 0
        };
    }
    componentDidMount() {
        this.initSerlians();
    }

    componentDidUpdate() {
        //Permet de calculer le nombre d'item à ajouter pour que les items visibles soient alignés à gauche
        if(this.state.serlians.length > 0) {
            let nbItemPerLine = window.innerWidth / 171;
            let nbLine = this.state.serlians.length / Math.floor(nbItemPerLine);
            let nbNewItem = Math.floor(nbItemPerLine) - (this.state.serlians.length - (Math.floor(nbItemPerLine) * Math.floor(nbLine)));

            if(nbNewItem !== this.state.insertItem && nbNewItem !== Math.floor(nbItemPerLine)) {
                this.setState({insertItem: nbNewItem});
            }
        }
    }

    sortSerlian(serlians) {
        return serlians.sort((a, b) =>
            this.state.sort * a.firstname.localeCompare(b.firstname)
        );
    }

    sort = () => {
        this.setState({sort: this.state.sort * -1})
    };

    setSerlians = (s) => {
        this.setState({serlians: this.sortSerlian(s.filter(serlian => !serlian.archive)), init: 0});
    };

    initSerlians = () => {
        if (this.state.serlians.length === 0 && this.state.init < 0) {
            this.setState({serlians: this.sortSerlian(this.getActiveSerlians(this.context.serlians))});
        }
    };

    getActiveSerlians = (serlians) => {
        serlians = serlians.filter((elt) => !elt.archive);
        return serlians;
    }

    // Permet d'afficher les items manquants
    renderMissItem = () => {
        let table = [];
        for(let i = 1; i <= this.state.insertItem; i++) {
            table.push(<i aria-hidden="true" className="hidden-i" key={i} />)
        }

        return table;
    };

    render() {
        return (
            this.context.serlians.length === 0 ?
                <div className="error-page">
                    <h2>Oups...</h2>
                    <p>Il semble qu'il n'y ai aucun Serlien de renseigné.</p>
                </div>
            :
                <>
                    <SearchSerlian setSerlians={this.setSerlians}/>
                    <div className="serlian_container">
                        {
                            this.state.serlians.length ?
                                this.state.serlians.map((s, index) =>
                                    <SerlianCard key={`serlian-card-${s.email}-${index}`} serlian={s}/>)
                            :
                                <span>Aucun serlien trouvé.</span>
                        }
                        {this.renderMissItem()}
                    </div>
                </>
        );
    }
}

export default Serlians;
