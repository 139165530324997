import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { PrimaryButton } from "../../../components/Buttons";
import Toast from "../../../components/Toast";
import { TiDelete } from "react-icons/ti";
import SerlianContext from "../../../context";
import { addSerlian } from "../../../utils/fetch/fetchUserAdmin";

const AddSerlian = () => {
  const serlianContext = useContext(SerlianContext);
  const history = useHistory();

  const [state, setState] = useState({
    serlian: {
      firstname: "",
      lastname: "",
      email: "",
      job: "",
      city: "",
      blog_article: "",
      linkedin: "",
      github: "",
      twitter: "",
    },
    toast: "",
    picture: null,
    errorPicture: false,
    errorMailFormat: false,
    errorMailExist: false,
    valueButton: "Ajouter",
    disabledButton: false,
    clazz: "form--modal",
    inputFileKey: Date.now(),
  });

  useEffect(() => {
    return () => {
      clearTimeout(state.toastTimer);
    };
  }, [state]);

  const handleChange = (e) => {
    let target = e.currentTarget;

    if (target.files) {
      state.picture = target.files[0];

      const file = target.files[0];
      let img = new Image();
      img.onload = function () {
        if (this.width !== this.height) {
          setState((prevState) => ({
            ...prevState,
            errorPicture: true,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            errorPicture: false,
          }));
        }
      };
      img.onerror = function () {
        alert("not a valid file: " + file.type);
      };
      img.src = window.URL.createObjectURL(file);
    } else {
      if (target.value.length < serlianContext.maxLength) {
        setState((prevState) => ({
          ...prevState,
          serlian: { ...prevState.serlian, [target.name]: target.value },
        }));
      }
      if (
        target.name === "email" &&
        (target.value.endsWith("@serli.com") || target.value === "")
      ) {
        setState((prevState) => ({
          ...prevState,
          errorMailFormat: false,
          errorMailExist: false,
        }));
      } else if (
        target.name === "email" &&
        !target.value.endsWith("@serli.com")
      ) {
        setState((prevState) => ({
          ...prevState,
          errorMailFormat: true,
          errorMailExist: false,
        }));
      }
    }
    setState((prevState) => ({
      ...prevState,
      serlian: prevState.serlian,
    }));
  };

  const handleAddSerlian = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      valueButton: <div className="loader" />,
      disabledButton: true,
    }));

    addNewSerlian();

    setTimeout(
      function () {
        setState({ toast: "" });
      }.bind(this),
      8000
    );
  };

  const addNewSerlian = () => {
    const formData = new FormData();
    formData.append("picture", state.picture);
    formData.append("serlian", JSON.stringify(state.serlian));

    addSerlian(formData, (response) => {
      if (response.name === "success") {
        setState((prevState) => ({
          ...prevState,
          toast: "SUCCESS",
          errorMailExist: false,
          inputFileKey: Date.now(),
          serlian: { ...prevState.serlian, haspicture: true },
        }));

        if (response.picture && response.picture !== "success") {
          setState({ toast: "ERROR_PICTURE" });
        }

        setTimeout(() => {
          history.push("/serliens/gestion");
        }, 1800);
      } else if (response.message === "EMAIL_EXIST") {
        setState({ errorMailExist: true });
      } else {
        setState({ toast: "ERROR" });
      }
    });
  };

  const renderToast = () => {
    switch (state.toast) {
      case "SUCCESS":
        return (
          <Toast
            type="success"
            title="Succès"
            message="Le serlien a bien été enregistré."
          />
        );
      case "ERROR":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="Le serlien n'a pas pu être enregistré"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="container container--column">
      {renderToast()}
      <div className="container container--column container--centered">
        <h2>Ajouter un nouveau serlien</h2>
        <em>
          Les champs suivis d'une étoile <u>doivent</u> être remplis
        </em>
      </div>
      <form onSubmit={handleAddSerlian} className="form">
        <label className="form__control">
          <span>Prénom *</span>
          <input
            type="text"
            name="firstname"
            value={state.serlian.firstname}
            className="form__input"
            onChange={handleChange}
            required
          />
        </label>
        <label className="form__control">
          <span>Nom *</span>
          <input
            type="text"
            className="form__input"
            name="lastname"
            onChange={handleChange}
            value={state.serlian.lastname}
            required
          />
        </label>
        <label className="form__control">
          <span>
            Email *
            {state.errorMailFormat && (
              <em>Cette adresse mail n'est pas de type serli.com</em>
            )}
            {state.errorMailExist && (
              <em>Cette adresse mail est déjà utilisée</em>
            )}
          </span>
          <input
            type="email"
            className={
              state.errorMailFormat || state.errorMailExist
                ? "form__input form-error"
                : "form__input"
            }
            name="email"
            onChange={handleChange}
            value={state.serlian.email}
            required
          />
        </label>
        <label className="form__control">
          <span>Poste *</span>
          <input
            type="text"
            className="form__input"
            name="job"
            onChange={handleChange}
            value={state.serlian.job}
            required
          />
        </label>
        <label className="form__control">
          <span>Agence</span>
          <select
            className="form__input"
            name="city"
            onChange={handleChange}
            value={state.serlian.city}
          >
            <option value="">Selectionner une option</option>
            <option value="Agence de Niort">Agence de Niort</option>
            <option value="Agence de Poitiers">Agence de Poitiers</option>
          </select>
        </label>
        <label className="form__control form__picture">
          <span>
            Photo{" "}
            {state.errorPicture && (
              <em>L'image doit être de dimension carrées (ex. 500x500)</em>
            )}
          </span>
          <input
            type="file"
            onChange={handleChange}
            key={state.inputFileKey}
            className={
              state.errorPicture ? "form__input form-error" : "form__input"
            }
            accept="image/jpeg"
          />
          <button
            onClick={state.resetField}
            type="reset"
            className={
              state.picture
                ? "cross-button--picture show"
                : "cross-button--picture"
            }
          >
            <TiDelete />
          </button>
        </label>
        <label className="form__control">
          <span>Lien présentation sur le blog</span>
          <input
            type="text"
            className="form__input"
            name="blog_article"
            onChange={handleChange}
            value={state.serlian.blog_article}
          />
        </label>
        <label className="form__control">
          <span>Lien linkedin</span>
          <input
            type="text"
            className="form__input"
            name="linkedin"
            onChange={handleChange}
            value={state.serlian.linkedin}
          />
        </label>
        <label className="form__control">
          <span>Lien github</span>
          <input
            type="text"
            className="form__input"
            name="github"
            onChange={handleChange}
            value={state.serlian.github}
          />
        </label>
        <label className="form__control">
          <span>Lien twitter</span>
          <input
            type="text"
            className="form__input"
            name="twitter"
            onChange={handleChange}
            value={state.serlian.twitter}
          />
        </label>
        <PrimaryButton
          disabled={state.errorMailFormat || state.errorPicture}
          id="btn-confirm"
          value={state.valueButton}
        />
      </form>
    </div>
  );
};

export default AddSerlian;
