import unorm from "unorm";

export const getAllClients = async () => {
  try {
    const response = await fetch(`/api/clients`, {
      method: "GET",
      credentials: "include",
      headers: { Accept: "application/json" },
    });

    const clients = await response.json();

    return clients;
  } catch (error) {
    console.error("Error fetching clients:", error);
  }
};

export const getSerliansByClient = async (clientName) => {
  try {
    const response = await fetch(
      `/api/clients/clientName/?clientName=${encodeURIComponent(clientName)}`,
      {
        method: "GET",
        credentials: "include",
        headers: { Accept: "application/json" },
      }
    );

    const serlians = await response.json();

    return serlians;
  } catch (error) {
    console.error("Error fetching clients:", error);
  }
};
