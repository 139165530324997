import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {PrimaryButton} from "../components/buttons/Buttons";
import Toast from "../components/toast/Toast";
import SerlianContext from "../context";
import EditSerlien from "./EditSerlien";
import {getSerlians} from "../utils/fetch/fetchUser";
import {addOrUpdateSerlian, deleteSerlian, archiveSerlian} from "../utils/fetch/fetchUserAdmin";
import {IoMdTrash} from "react-icons/io";
import {FaArchive} from "react-icons/fa";
import {FiEdit3} from "react-icons/fi";
import {TiDelete} from "react-icons/ti";
import {MdUnarchive} from "react-icons/md";
import "./AddSerlian.scss";

class AddSerlian extends Component {

    static contextType = SerlianContext;

    constructor(props) {
        super(props);
        this.state = {
            serlian: {
                firstname: "",
                lastname: "",
                email: "",
                job: "",
                city: ""
            },
            picture: null,
            errorPicture: false,
            errorMailFormat: false,
            errorMailExist: false,
            valueButton: "Envoyer",
            disabledButton: false,
            clazz: "form-modal",
            inputFileKey: Date.now(),
            isEdit: false,
            sort: 1,
            serlians: [],
            archiveSerlians: [],
            filter: "active"
        };

        this.serlian = {
            firstname: "",
            lastname: "",
            email: "",
            job: "",
            city: ""
        }
    }

    componentDidMount() {
        if(window.location.pathname === "/serliens/add") {
            this.setState({isEdit: false, serlian: this.serlian});
        } else {
            this.setState({isEdit: true});
        }
        let allSerlians = this.sortSerlian(this.context.serlians);
        let serlians = this.getActiveSerlians(allSerlians);
        let archiveSerlians = this.getArchiveSerlians(allSerlians);
        this.setState({ serlians, archiveSerlians });

        this.unlisten = this.props.history.listen((location, action) => {
            if(location.pathname === "/serliens/add") {
                this.setState({isEdit: false, serlian: this.serlian});
            }
            else {
                this.setState({isEdit: true});
            }
        })
    }

    componentWillUnmount() {
        this.unlisten();
    }

    updateSerlians = () => {
        getSerlians((allSerlians) => {
            let serlians = allSerlians && this.sortSerlian(this.getActiveSerlians(allSerlians));
            let archiveSerlians = allSerlians && this.sortSerlian(this.getArchiveSerlians(allSerlians));
            this.setState( {serlians, archiveSerlians});
        });
    };

    getActiveSerlians = (serlians) => {
        serlians = serlians.filter((elt) => !elt.archive);
        return serlians;
    };

    getArchiveSerlians = (serlians) => {
        serlians = serlians.filter(elt => elt.archive);
        return serlians;
    };

    deleteSerlian = (serlian) => {
        deleteSerlian(serlian, (response) => {
            if (response.name === "success") {
                this.setState({toast: "SUCCESSDEL"});
                this.updateSerlians();
            } else {
                this.setState({toast: "ERRORDEL"});
            }
        })
    }

    archiveSerlian = (serlian) => {
        archiveSerlian(serlian, (response) => {
            if (response.name === "success") {
                this.setState({toast: "SUCCESSARCH"});
                this.updateSerlians();
            } else {
                this.setState({toast: "ERRORARCH"});
            }
        })
    }

    sortSerlian(serlians) {
        return serlians.sort((a, b) =>
            this.state.sort * a.firstname.localeCompare(b.firstname)
        );
    }

    onChangeMode = (e) => {
        if(!e.target.checked) {
            this.props.history.push('/serliens/add');
        }
        else {
            this.props.history.push('/serliens/gestion');
        }
    };

    handleClick = (event, serlian, unarchive) => {
        const { filter } = this.state;
        switch(filter){
            case "active":
                this.archiveOrDeleteSerlian(serlian, "archive");
                break;
            case "archive":
                if(unarchive) {
                    this.archiveOrDeleteSerlian(serlian, "unarchive");
                }
                else {
                    this.archiveOrDeleteSerlian(serlian, "delete");
                }
                break;
        }
    };

    resetField = () => {
        this.setState({picture: null, errorPicture: false, inputFileKey: Date.now()});
    };

    toggleModalEdit = (target) => {
        if(target.classList.contains("toggle-add")) {
            this.setState({clazz: "form-modal"});
        }
    };

    handleChange = (e) => {
        let target = e.currentTarget;
        if(target.getAttribute("name") === "filter"){
            this.setState( { filter : target.value });
        } else {
            if (target.files) {
                const self = this;
                this.state.picture = target.files[0];
                let file = target.files[0];
                let img = new Image();
                img.onload = function () {
                    if (this.width !== this.height) {
                        self.setState({errorPicture: true})
                    } else {
                        self.setState({errorPicture: false})
                    }
                };
                img.onerror = function () {
                    alert("not a valid file: " + file.type);
                };
                img.src = window.URL.createObjectURL(file);
            } else {
                if(target.value.length < this.context.maxLength) {
                    this.state.serlian[target.name] = target.value;
                }
                if (target.name === "email" && (target.value.endsWith("@serli.com") || target.value === "")) {
                    this.setState({errorMailFormat: false, errorMailExist: false})
                }
                else if (target.name === "email" && !target.value.endsWith("@serli.com")) {
                    this.setState({errorMailFormat: true, errorMailExist: false})
                }
            }
            this.setState({serlian: this.state.serlian});
        }
    };

    archiveOrDeleteSerlian = (serlian, action) => {
        if(confirm(action === "delete" ? "Voulez vous vraiment supprimer ce serlien ?" : action === "archive" ? "Voulez vous vraiment archiver ce serlien ?" : "Voulez vous vraiment désarchiver ce serlien ?")) {
            if(action === "delete"){
                this.deleteSerlian(serlian);
            } else if(action === "archive" || action === "unarchive"){
                this.archiveSerlian(serlian);
            }
            setTimeout(function () {
                this.setState({toast: ""});
            }.bind(this), 8000);
        }

    };

    addOrUpdateSerlian = () => {
        const {serlian, picture} = this.state;
        const formData = new FormData();
        formData.append("picture", picture);
        formData.append("serlian", JSON.stringify(serlian));
        addOrUpdateSerlian(formData, this.state.isEdit, (response) => {
            if (response.name === "success") {
                this.setState({toast: "SUCCESS", errorMailExist: false, inputFileKey: Date.now()});
                if(!this.state.isEdit) {
                    serlian.firstname = "";
                    serlian.lastname = "";
                    serlian.email = "";
                    serlian.job = "";
                    serlian.city = "";
                    this.setState({serlian, picture: null})
                }
                if(response.picture && response.picture !== "success") {
                    this.setState({toast: "ERROR_PICTURE"});
                }
                this.updateSerlians();
            }
            else if(response.message === "EMAIL_EXIST") {
                this.setState({errorMailExist: true})
            } else {
                this.setState({toast: "ERROR"});
            }
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({valueButton: <div className="loader"/>, disabledButton: true});
        this.addOrUpdateSerlian();
        this.setState({valueButton: "Envoyer", disabledButton: false});
        setTimeout(function () {
            this.setState({toast: ""});
        }.bind(this), 8000);
    };

    renderToast = () => {
        switch(this.state.toast) {
            case "SUCCESS":
                return <Toast type="success"
                              title="Succès"
                              message={"Le Serlien a bien été " + (this.state.isEdit ? "modifié." : "ajouté.")} />;
            case "SUCCESSARCH":
                return <Toast type="success"
                              title="Succès"
                              message={"Le Serlien a bien été " + (this.state.filter === "active" ? "" : "dés") + "archivé."}/>;
            case "SUCCESSDEL":
                return <Toast type="success"
                              title="Succès"
                              message="Le Serlien a bien été supprimé."/>;
            case "EXIST":
                return <Toast type="error"
                              title="Erreur"
                              message="Cette adresse mail est déjà utilisée."/>;
            case "ERROR":
                return <Toast type="error"
                              title="Erreur"
                              message={"Le Serlien n'a pas pu être " + (this.state.isEdit ? "modifié" : "ajouté") + ", veuillez réessayer plus tard."} />;
            case "ERRORDEL":
                return <Toast type="error"
                              title="Erreur"
                              message="Le Serlien n'a pas pu être supprimé, veuillez réessayer plus tard." />;
            case "ERRORARCH":
                return <Toast type="error"
                              title="Erreur"
                              message={"Le Serlien n'a pas pu être " + (this.state.filter === "active" ? "" : "dés") + "archivé, veuillez réessayer plus tard."} />;
            case "ERROR_PICTURE":
                return <Toast type="error"
                              title="Erreur"
                              message="L'image n'a pas pu être enregistrée, veuillez réessayer sur le profil de l'utilisateur." />;
            default:
                return null;
        }
    };

    render() {
        const {serlian, isEdit, filter} = this.state;
        let serlians = filter === "active" ? this.state.serlians : this.state.archiveSerlians;
        if(this.context.serlianConnected.role !== "admin") {
            return (<Redirect to="/" />)
        }
        return (
            <div className="add-mission-container">
                {this.renderToast()}
                <h2>{isEdit ? "Gestion des Serliens" : "Ajouter un Serlien"}</h2>
                {!isEdit && <em>Les champs suivis d'une étoile <u>doivent</u> être remplis</em>}
                    <form onSubmit={this.handleSubmit} className="form form-serlian">
                        <label className="form-control edit-control">
                            <span>Mode Edition</span>
                            <div className="switch">
                                <input type="checkbox" name="mode" checked={this.state.isEdit}
                                       onChange={this.onChangeMode}/>
                                <span className="slider round"/>
                            </div>
                        </label>
                        {
                            !isEdit ?
                                <>
                                    <label className="form-control">
                                        <span>Prénom *</span>
                                        <input type="text" className="form-input" name="firstname"
                                               onChange={this.handleChange}
                                               value={serlian.firstname} required/>
                                    </label>
                                    <label className="form-control">
                                        <span>Nom *</span>
                                        <input type="text" className="form-input" name="lastname"
                                               onChange={this.handleChange} value={serlian.lastname} required/>
                                    </label>
                                    <label className="form-control">
                                        <span>Email *
                                            {this.state.errorMailFormat &&
                                            <em>Cette adresse mail n'est pas de type serli.com</em>}
                                            {this.state.errorMailExist && <em>Cette adresse mail est déjà utilisée</em>}
                                        </span>
                                        <input type="email"
                                               className={this.state.errorMailFormat || this.state.errorMailExist ? "form-input form-error" : "form-input"}
                                               name="email" onChange={this.handleChange} value={serlian.email} required/>
                                    </label>
                                    <label className="form-control">
                                        <span>Poste *</span>
                                        <input type="text" className="form-input" name="job" onChange={this.handleChange}
                                               value={serlian.job} required/>
                                    </label>
                                    <label className="form-control">
                                        <span>Ville</span>
                                        <input type="text" className="form-input" name="city" onChange={this.handleChange}
                                               value={serlian.city}/>
                                    </label>
                                    <label className="form-control">
                                        <span>Photo {this.state.errorPicture &&
                                        <em>L'image doit être de dimension carrées (ex. 500x500)</em>}
                                        </span>
                                        <input type="file" onChange={this.handleChange} key={this.state.inputFileKey}
                                               className={this.state.errorPicture ? "form-input form-error" : "form-input"}
                                               accept="image/jpeg"/>
                                        <button onClick={this.resetField} type="Reset"
                                                className={this.state.picture ? "crossButton show" : "crossButton"}>
                                            <TiDelete/>
                                        </button>
                                    </label>
                                    <PrimaryButton
                                        disabled={this.state.disabledButton || this.state.errorMailFormat || this.state.errorPicture}
                                        id="btn-confirm"
                                        value={this.state.valueButton}/>
                                </>
                            :
                                <>
                                    <div className="form-control">
                                        <select className="form-input" name="filter" value={this.state.filter}
                                                onChange={this.handleChange}>
                                            <option value="active"> Serliens actifs ({this.state.serlians.length})</option>
                                            <option value="archive"> Serliens archivés ({this.state.archiveSerlians.length})
                                            </option>
                                        </select>
                                    </div>
                                    <table className="gestion-serlian" cellSpacing="0">
                                        <thead>
                                        <tr>
                                            <th>Prénom</th>
                                            <th>Nom</th>
                                            {
                                                filter === "active" ?
                                                    <th><FiEdit3 style={{fontSize: "24px"}}/></th>
                                                :
                                                    <th><MdUnarchive style={{fontSize: "24px"}} /></th>
                                            }
                                            <th>
                                                {
                                                    filter === "active" ?
                                                        <FaArchive style={{fontSize: "24px"}}/>
                                                        :
                                                        <IoMdTrash style={{fontSize: "24px"}}/>
                                                }
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            serlians && serlians.map((s, index) => {
                                                    return (
                                                        <tr style={{position: "relative"}} key={index}>
                                                            <td>{s.firstname}</td>
                                                            <td>{s.lastname}</td>
                                                            {
                                                                filter === "active" ?
                                                                    <td>
                                                                        <button type="button" className="btn-round" onClick={() => {
                                                                            this.setState({clazz: "form-modal toggle-add", serlian : {...s}});
                                                                            this.resetField();
                                                                        }}>
                                                                            <FiEdit3/>
                                                                        </button>
                                                                    </td>
                                                                :
                                                                    <td>
                                                                        <button type="button" className="btn-round" onClick={(e) => this.handleClick(e, s, true)}>
                                                                            <MdUnarchive />
                                                                        </button>
                                                                    </td>
                                                            }
                                                            <td>
                                                                <button type="button" className="btn-round"
                                                                        onClick={(event) => this.handleClick(event, s, false)}>
                                                                    {filter === "active" ? <FaArchive/> : <IoMdTrash/>}
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </>
                        }
                    </form>
                {
                    this.state.isEdit &&
                    <EditSerlien
                        toggleModal={this.toggleModalEdit}
                        clazz={this.state.clazz}
                        serlian={this.state.serlian}
                        handleSubmit={this.handleSubmit}
                        handleChange={this.handleChange}
                        valueButton={this.state.valueButton}
                        errorMailFormat={this.state.errorMailFormat}
                        errorMailExist={this.state.errorMailExist}
                        errorPicture={this.state.errorPicture}
                        picture={this.state.picture}
                        inputFileKey={this.state.inputFileKey}
                        resetField={this.resetField}
                    />
                }
            </div>
        );
    }
}

export default AddSerlian;