import React, {Component} from "react";
import SerlianContext from "../../context";
import {FaSearch} from 'react-icons/fa';
import {TiDelete} from 'react-icons/ti';
import  {getSkills} from "../../utils/fetch/fetchSkills";
import {getMissions} from "../../utils/fetch/fetchMissions";
import "./searchSerlian.scss";

class SearchSerlian extends Component {

    static contextType = SerlianContext;

    constructor(props) {
        super(props);
        this.state = {
            skills: [],
            missions:[],
            userSkill: [],
            searchMethod: '',
            updateSerlian: [],
            searchField : ""
        };
    }

    componentDidMount() {
        this.getSkills();
        this.getMissions();
    }

    getSkills = () => {
        getSkills((skills) => {
            this.setState({skills});
        });
    };

    getMissions = () => {
        getMissions((missions) => {
            this.setState({missions});
        })
    }

    getSerlian = (id) => {
        this.context.serlians.map((serlian) => {
            if(serlian.id === id && !this.state.updateSerlian.includes(serlian)) {
                this.state.updateSerlian.push(serlian);
            }
        })
    };

    getSerlianBySkills = (skills) => {
        this.context.serlians.map((serlian) => {
            serlian.skills && serlian.skills.map((skill) => {
                skills && skills.map((s) => {
                    if (skill === s && !this.state.updateSerlian.includes(serlian)) {
                        this.state.updateSerlian.push(serlian);
                    }
                })
            })
        })
    };

    onChange = (event) => {
        let source = event.target;
        this.setState({updateSerlian : [], searchField: source.value});
        this.search(source.value);
    };

    handleChange = (event) => {
        this.setState({searchMethod : event.target.value}, () => this.search(this.state.searchField));
    };

    noAccent = (string) => {
        let accent = [
            /[\300-\306]/g, /[\340-\346]/g, // A, a
            /[\310-\313]/g, /[\350-\353]/g, // E, e
            /[\314-\317]/g, /[\354-\357]/g, // I, i
            /[\322-\330]/g, /[\362-\370]/g, // O, o
            /[\331-\334]/g, /[\371-\374]/g, // U, u
            /[\321]/g, /[\361]/g, // N, n
            /[\307]/g, /[\347]/g, // C, c
        ];
        let noAccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c'];
        accent.map( ( acc , i ) => {
            string = string.replace(acc, noAccent[i])
        });
        return string;
    }

    search = (search) => {
        if(this.state.searchMethod === "name") {
            this.searchSerlian(search);
        }
        else if (this.state.searchMethod === "skills") {
            this.searchSkills(search);
        }
        else if (this.state.searchMethod === "missions") {
            this.searchClient(search);
        }
        else {
            this.searchSerlian(search);
            this.searchSkills(search);
            this.searchClient(search);
        }
    };

    searchSerlian = (search) => {
        search = search.toLowerCase();
        search = search.split(",").filter(item => item.trim() !== "");
        let serlians = this.context.serlians;
        if(search.length === 0) {
            this.props.setSerlians(this.context.serlians);
        }
        else {
            serlians.map(serlian => {
                let firstname = serlian.firstname.toLowerCase();
                let firstnameNA = this.noAccent(firstname);
                let lastname = serlian.lastname.toLowerCase();
                let lastnameNA = this.noAccent(lastname);
                search.map(searchItem => {
                    let names = searchItem.split(" ").filter(item => item.trim() !== "");
                    names.map((name, index) => {
                        if (names.length === 1 ) {
                            if (firstname.startsWith(name) || lastname.startsWith(name)){
                                return this.getSerlian(serlian.id);
                            } else if (firstnameNA.startsWith(name) || lastnameNA.startsWith(name)){
                                return this.getSerlian(serlian.id);
                            }
                        }
                        else if (names.length > 1) {
                            if (((firstname.startsWith(name) && lastname.startsWith(names[index + 1]))
                                || (firstname.startsWith() && lastname.startsWith(name)))) {
                                return this.getSerlian(serlian.id);
                            } else if (((firstnameNA.startsWith(name) && lastnameNA.startsWith(names[index + 1]))
                                || (firstnameNA.startsWith() && lastnameNA.startsWith(name)))) {
                                return this.getSerlian(serlian.id);
                            }
                        }
                    })
                })
            });
            this.props.setSerlians(this.state.updateSerlian);
        }
    };

    searchSkills = (search) => {
        search = search.toLowerCase();
        search = search.split(",");
        search = search.filter(item => item.trim() !== "");
        let length = search.length;
        if(length === 0) {
            this.props.setSerlians(this.context.serlians);
        }
        else {
            let matchSkills = [];
            this.state.skills.map( skill => {
                search.map( se => {
                    se = se.trim();
                    if(skill.skill.toLowerCase().startsWith(se)){
                        matchSkills.push(skill.id_skill)
                        //return this.searchMatch(skill.id_skill);
                    }
                })
            });
            this.getSerlianBySkills(matchSkills);
            this.props.setSerlians(this.state.updateSerlian);
        }
    };

    searchClient = (search) => {
        search = search.toLowerCase();
        search = search.split(",");
        search = search.filter(item => item.trim() !== "");
        let length = search.length;
        if(length === 0) {
            this.props.setSerlians(this.context.serlians);
        }
        else {
            this.state.missions.map( mission => {
                search.map(se => {
                    se = se.trim();
                    if(mission.client_name.toLowerCase().startsWith(se)){
                        return this.getSerlian(mission.id_user);
                    }
                })
            });
            this.props.setSerlians(this.state.updateSerlian);
        }
    };

    resetField = () => {
        this.setState({searchField: ""});
        this.props.setSerlians(this.context.serlians);
    };

    render() {
        return (
            <div className="search-form">
                <div className="search-field-container">
                    <select className="search-field"  value={this.state.searchMethod} onChange={this.handleChange}>
                        <option className="option-text" value="">-- Catégorie --</option>
                        <option className="option-text" value="name">Nom/prénom</option>
                        <option className="option-text" value="skills">Compétences</option>
                        <option className="option-text" value="missions">Missions</option>
                    </select>
                </div>
                <div className="search-container">
                    <div className="logo"><FaSearch/></div>
                    <input id="input" className="search" type="text" value={this.state.searchField} onChange={this.onChange} placeholder="Rechercher un serlien..." />
                    <button onClick={this.resetField} type="Reset" className={this.state.searchField ? "crossButton show": "crossButton"}>
                        <TiDelete/>
                    </button>
                </div>
            </div>
        );
    }

}

export default SearchSerlian;