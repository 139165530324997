import React, {Component} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from "./screens/Home";
import SerlianProfil from "./screens/SerlianProfil";
import GestionMissions from "./screens/missions/GestionMissions";
import GestionSkills from "./screens/GestionSkills";
import SerlianContext from "./context"
import Page from "./containers/Page";
import IsPending from "./components/isPending/IsPending";
import Contact from "./screens/Contact";
import AddSerlian from "./screens/AddSerlian";
import ErrorSerlianProfil from "./screens/ErrorProfil/ErrorSerlianProfil";
import {getSerlians} from "./utils/fetch/fetchUser";
import './themes/app.scss';
import "./themes/form.scss";

class AppAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            serlians: [],
            width: 0,
            isPending: true,
            errorConn: false
        }
    }

    componentDidMount() {
        this.getSerlians();
        this.getSerlianConnected();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({width: window.innerWidth});
    };

    getSerlians = () => {
        getSerlians((serlians) => {
            if(serlians.message && serlians.message === "CONNECTION_FAILED") {
                this.setState({errorConn: true, isPending: false});
            }
            else if(serlians.message && serlians.message === "NO_RESULT") {
                this.setState({isPending: false});
            }
            else {
                this.setState({serlians, isPending: false});
            }
        })
    }

    getSerlianConnected = () => {
        fetch("/api/global/me", {
            method: 'GET',
            credentials: 'include', //on inclut les cookies
            headers: {"Accept": "application/json"}
        })
            .then(r => r.json())
            .then(serlianConnected => {
                this.setState({serlianConnected});
            });
    };

    render() {
        return (
            <SerlianContext.Provider
                value={{
                    serlians:this.state.serlians,
                    serlianConnected: this.state.serlianConnected,
                    isMobile: navigator.userAgent.match(/Android/i)
                        || navigator.userAgent.match(/webOS/i)
                        || navigator.userAgent.match(/iPhone/i)
                        || navigator.userAgent.match(/iPod/i)
                        || navigator.userAgent.match(/BlackBerry/i)
                        || navigator.userAgent.match(/Windows Phone/i),
                    isTablet: navigator.userAgent.match(/iPad/i)
                        || navigator.userAgent.match(/Tablet/i),
                    maxLength: 10485760
                }}>
                <Router>
                    <Page>
                        {
                            this.state.isPending ?
                                <IsPending />
                                :
                                this.state.errorConn ?
                                    <div className="error-page">
                                        <h2>Oups...</h2>
                                        <p>Une erreur de connexion  est survenue...</p>
                                    </div>
                                    :
                                    <Switch>
                                        <Route exact path="/" component={Home}/>
                                        <Route path="/serliens/(add|gestion)" component={AddSerlian} />
                                        <Route path="/serliens/error" component={ErrorSerlianProfil} />
                                        <Route path="/serliens/:name" component={SerlianProfil}/>
                                        <Route path="/contact" render={(props) => <Contact {...props} serlianConnected={this.state.serlianConnected} />} />
                                        <Route path="/missions/(add|gestion)" component={GestionMissions} />
                                        <Route path="/skills/gestion" component={GestionSkills} />
                                    </Switch>
                        }
                    </Page>
                </Router>
            </SerlianContext.Provider>
        );
    }
}

ReactDOM.render(<AppAdmin />, document.getElementById("app"));
