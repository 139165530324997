import React, {Component} from "react";
import {FaSpinner} from "react-icons/fa";
import "./isPending.scss";

class IsPending extends Component {
    render() {
        return (
          <div className="is-pending">
              <FaSpinner />
          </div>
        );
    }
}

export default IsPending;