import React, { Component } from "react";
import { PrimaryButton } from "../../Buttons";
import SerlianContext from "../../../context";

class AddConference extends Component {
  static contextType = SerlianContext;

  constructor(props) {
    super(props);

    this.state = {
      conference: this.props.conf,
    };
  }

  componentDidUpdate() {
    if (this.props.conf !== this.state.conference) {
      this.setState({ conference: this.props.conf });
    }
  }

  createRangeYear = () => {
    let years = [];
    for (let i = 1900; i <= this.props.actualYear; i++) {
      years.push(i);
    }
    return years;
  };

  handleMousDown = (event) => {
    if (event.target.length > 10) {
      event.target.size = 10;
    }
  };

  handleChange = (event) => {
    let target = event.currentTarget;
    let maxLen = this.context.maxLength;
    let { conference } = this.state;
    switch (target.name) {
      case "place":
        if (target.value.length < maxLen) {
          conference.place = target.value;
        }
        break;
      case "title":
        if (target.value.length < maxLen) {
          conference.title = target.value;
        }
        break;
      case "year":
        if (target.value.length < maxLen) {
          conference.year = target.value;
        }
        break;
      case "video_link":
        if (target.value.length < maxLen) {
          conference.video_link = target.value;
        }
        break;
      case "article_link":
        if (target.value.length < maxLen) {
          conference.article_link = target.value;
        }
        break;
      default:
        break;
    }
    this.setState({ conference });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.props.editing) {
      this.props.updateConference(this.state.conference);
    } else {
      this.props.addConference(this.state.conference);
    }
  };

  render() {
    const { conference } = this.state;
    return (
      <form
        className={this.props.clazz}
        onClick={(e) => this.props.toggleModal(e.target)}
        onSubmit={this.handleSubmit}
      >
        <div className="form--modal__container overflow-auto">
          <div className="container container--column container--centered">
            {this.props.editing ? (
              <h2>Modifier une conférence</h2>
            ) : (
              <h2>Ajouter une conférence</h2>
            )}
          </div>
          <div className="input">
            <label className="form__control">
              <span>Nom de l'événement</span>
              <input
                name="place"
                type="text"
                className="form__input"
                value={conference.place}
                placeholder="Entrer un événement ..."
                onChange={this.handleChange}
                required
              />
            </label>
            <label className="form__control">
              <span>Titre de la conférence</span>
              <input
                name="title"
                type="text"
                className="form__input"
                value={conference.title}
                placeholder="Entrer un titre ..."
                onChange={this.handleChange}
                required
              />
            </label>
            <label className="form__control">
              <span>Année</span>
              <input
                name="year"
                type="number"
                className="form__input"
                value={conference.year}
                min="1900"
                max={this.props.actualYear}
                placeholder="Entrer une année ..."
                onChange={this.handleChange}
              />
            </label>
            <label className="form__control">
              <span>Lien Vidéo</span>
              <input
                name="video_link"
                type="url"
                className="form__input"
                value={conference.video_link}
                placeholder="Entrer un lien de vidéo ..."
                onChange={this.handleChange}
              />
            </label>
            <label className="form__control">
              <span>Lien Article</span>
              <input
                name="article_link"
                type="url"
                className="form__input"
                value={conference.article_link}
                placeholder="Entrer un lien d'article ..."
                onChange={this.handleChange}
              />
            </label>
          </div>
          <div className="container container--column container--centered">
            <PrimaryButton
              value={this.props.editing ? "Modifier" : "Ajouter"}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default AddConference;
