export const sendIssue = (form, title, type, message, email, callback) => {

    fetch("/api/global/issue", {
        method: "POST",
        credentials: "include", //on inclut les cookies
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify({
            "title": title,
            "labels": [type],
            "body": message + "\n de " + email
        })
    })
        .then(response => response.json())
        .then(response => {
            callback && callback(response);
        });
};