import React, { useState, useEffect } from "react";
import { getImageIfValid, SERLIAN_DEFAULT_PICTURE } from "../utils/Images";
import { Link } from "react-router-dom";

const SerlianCard = ({ serlian }) => {
  const [picture, setPicture] = useState(SERLIAN_DEFAULT_PICTURE);

  useEffect(() => {
    getImage();
  }, [serlian]);

  const getImage = () => {
    const img = serlian.picture;
    if (img !== SERLIAN_DEFAULT_PICTURE && img !== picture) {
      getImageIfValid(img).then((result) => {
        if (result) {
          setPicture(result);
        }
      });
    }
  };

  const getFirstAndLastName = () => {
    let mail = serlian.email;
    let split = mail.split("@");
    return split[0];
  };

  return (
    <Link
      to={{ pathname: `/serliens/${getFirstAndLastName()}` }}
      className="card card--serlian"
    >
      <img className="img" src={picture} alt="photo" />
      <div className="card__insert">
        <h4>{serlian.firstname}</h4>
        <h4>{serlian.lastname}</h4>
        {/* <h5 className="card__text">{serlian.job}</h5> */}
      </div>
    </Link>
  );
};

export default SerlianCard;
