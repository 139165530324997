export const skillValidation = (idSkill, callback) => {
    fetch(`/api/skills/${idSkill}`, {
        method: "PUT",
        credentials: "include", //on inclut les cookies
        headers: {
            "Accept" : "application/json",
        }
    })
        .then(response => response.json())
        .then(response => {
            callback && callback(response);
        })
};

export const deleteSkill = (idSkill, callback) => {
    fetch(`/api/skills/${idSkill}`, {
        method: "DELETE",
        credentials: "include", //on inclut les cookies
        headers: {
            "Accept" : "application/json",
        }
    })
        .then(response => response.json())
        .then(response => {
            callback && callback(response);
        })
};

export const updateOrAddSkill = (update, skill, callback) => {
    fetch("/api/skills", {
        method: update ? "PUT" : "POST",
        credentials: "include", //on inclut les cookies
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify({
            skill
        })
    })
        .then(response => response.json())
        .then(response => {
            callback && callback(response);
        });
};