import React from "react";

export const UserButton = ({ user, displayMenu }) => (
  <button className="btn--transparent">
    {user && (
      <img
        src={user.picture}
        alt="photo"
        className="menu__user__picture"
        id="user-picture"
        onClick={displayMenu}
      />
    )}
  </button>
);

export const LogOutButton = ({ icon }) => (
  <a href="/.well-known/otoroshi/logout" className="deconnexion">
    <div className="link">
      {icon}
      Déconnexion
    </div>
  </a>
);

export const PrimaryButton = ({ value, id, disabled, onClick }) => (
  <button
    onClick={onClick}
    type="submit"
    disabled={disabled}
    id={id}
    className="btn btn--blue btn--centered"
  >
    {value}
  </button>
);
