export const getSkills = async (callback) => {
  try {
    const response = await fetch("/api/skills", {
      method: "GET",
      credentials: "include", // include cookies
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const skills = await response.json();
    callback && callback(skills);
  } catch (error) {
    console.error("Error fetching skills:", error);
  }
};

export const addSkills = (
  currentTarget,
  target,
  skillsToAdd,
  serlian,
  callback
) => {
  fetch("/api/skills/serlian", {
    method: "POST",
    credentials: "include", //on inclut les cookies
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      skills: skillsToAdd,
      serlian: serlian,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      callback && callback(response);
    });
};

export const getUserSkills = (idSerlian, callback) => {
  fetch(`/api/skills/${idSerlian}`, {
    method: "GET",
    credentials: "include", //on inclut les cookies
    headers: { Accept: "application/json" },
  })
    .then((response) => response.json())
    .then((skills) => {
      callback && callback(skills.skills);
    });
};

export const removeSkill = (skill, idSerlian, callback) => {
  fetch("/api/skills", {
    method: "DELETE",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      skills: skill,
      idSerlian: idSerlian,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      callback && callback(response);
    });
};
