import React, {Component} from 'react';
import './header.scss';
import Menu from "../menu/Menu";
import {Link} from "react-router-dom";

class Header extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <header className="banner">
                <nav className="navbar">
                    <div className="navbar-brand"><Link to={{pathname: "/"}}>TROMBINOSCOPE</Link></div>
                    <Menu user={this.props.user} />
                </nav>
            </header>
        );
    }
}

export default Header;
