import React from "react";
import { PrimaryButton } from "../../../components/Buttons";

const EditSkill = (props) => {
  const { skill } = props;

  return (
    <form
      className={props.clazz}
      onClick={(e) => props.toggleModal(e.target)}
      onSubmit={props.handleSubmit}
    >
      <div className="form--modal__container overflow-auto">
        <div className="container container--column container--centered">
          <h2>Editer la compétence</h2>
        </div>
        <label className="form__control">
          <span>Nom</span>
          <input
            type="text"
            className="form__input"
            name="name"
            onChange={props.handleChange}
            value={skill.skill}
            required
          />
        </label>
        <label className="form__control">
          <span>Validée</span>
          <div>
            <em>Cochez cette case pour valider la compétence</em>
            <input
              type="checkbox"
              name="validation"
              onChange={props.handleChange}
              checked={skill.validation}
            />
          </div>
        </label>
        <div className="container container--column container--centered">
          <PrimaryButton id="btn-confirm" value={props.valueButton} />
        </div>
      </div>
    </form>
  );
};

export default EditSkill;
