import React from "react";

const ErrorSerlianProfil = () => {
  return (
    <div className="container container--column container--page-error">
      <h2>Oups...</h2>
      <p>
        Il semble que vous n'ayez pas de profil sur le trombinoscope. Pour y
        remédier, veuillez contacter Anne Roquain par mail :
      </p>
      <p>anne.roquain@serli.com</p>
    </div>
  );
};

export default ErrorSerlianProfil;
