import React, {Component} from "react";
import AddFormation from "./AddFormation";
import Toast from "../../toast/Toast";
import {updateFormations} from "../../../utils/fetch/fetchFormations";
import {MdAdd} from "react-icons/md";
import {GiTeacher} from "react-icons/gi";
import {GoPrimitiveDot} from "react-icons/go";
import {FiEdit3} from "react-icons/fi";
import {IoMdTrash} from "react-icons/io";
import "./formations.scss";

class Formations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clazz: "form-modal",
            isEdited: false,
            formation: ""
        }
    }

    toggleModalAdd = (target) => {
        if(target.classList.contains("toggle-add")) {
            this.setState({clazz: "form-modal"})
        }
    };

    deleteFormation = (index) => {
        let {formations} = this.props;

        formations.splice(index, 1);

        updateFormations(formations, this.props.idSerlian)
            .then(response => {
                if (response.name === "success") {
                    this.setState({toast: "SUCCESS"});
                }
                if (response.message && response.message === "NOT_ALLOWED") {
                    this.setState({toast: "NOT_ALLOWED"});
                }

                const toastTimer = setTimeout(function () {
                    this.setState({toast: ""});
                }.bind(this), 8000);
                this.setState({toastTimer});
            })
    };

    renderToast = () => {
        switch (this.state.toast) {
            case "SUCCESS":
                return <Toast type="success"
                              title="Succès"
                              message="La formation a bien été supprimée."/>;
            case "ERROR" :
                return <Toast type="error"
                              title="Erreur"
                              message="La formation n'a pas pu être ajoutée, veuillez réessayer plus tard."/>;
            case "EXIST":
                return <Toast type="error"
                              title="Erreur"
                              message="La formation est déjà renseignée."/>;
            case "NOT_ALLOWED":
                return <Toast type="error"
                              title="Erreur"
                              message="Vous n'êtes pas autorisé à faire ceci."/>;
            case "EMPTY":
                return <Toast type="error"
                              title="Erreur"
                              message="Vous devez saisir une formation."/>;
            default:
                return null;
        }
    };

    render() {
        const {formations} = this.props;
        return (
            ((formations && formations.length > 0) || this.props.isEditable) &&
                <div className="formation-container">
                    {this.renderToast()}
                    <div className="formation-header">
                        <h3 className="formation-title"><GiTeacher/> Formations proposées</h3>
                        {
                            this.props.isEditable &&
                            <div className="edit-btn-container">
                                <button className="btn"
                                        title="Ajouter une formation"
                                        onClick={() => this.setState({clazz: "form-modal toggle-add", formation: ""})}>
                                    <MdAdd/>
                                </button>
                                {
                                    formations && formations.length > 0 &&
                                    <button className={"btn" + (this.state.isEdited ? " edit" : "")}
                                            name = "change"
                                            title="Modifier les compétences"
                                            onClick = {() => this.setState({isEdited: !this.state.isEdited})}>
                                        <FiEdit3/>
                                    </button>
                                }
                            </div>
                        }
                    </div>
                    <div className="formations">
                        {
                            formations && formations.length > 0 ?
                                formations.map((formation, index) => {
                                    return (
                                        <div className="div-formation" key={index}>
                                            {
                                                this.props.isEditable && this.state.isEdited ?
                                                    <>
                                                        <button className="btn"
                                                                title="Supprimer la formation"
                                                                onClick={() => this.deleteFormation(index)}>
                                                            <IoMdTrash/>
                                                        </button>
                                                        <button className="btn"
                                                                title="Modifier la formation"
                                                                onClick={() => this.setState({formation, clazz: "form-modal toggle-add"})}>
                                                            <FiEdit3/>
                                                        </button>
                                                    </>
                                                    :
                                                    <GoPrimitiveDot className="svg"/>
                                            }
                                            <span className="formation">{formation}</span>
                                        </div>
                                    );
                                })
                            :
                                <i className="defaultPhrase">Vous êtes formateur ? Renseigner ici les sujets de vos formations.</i>
                        }
                    </div>

                    {
                        this.props.isEditable &&
                        <AddFormation clazz={this.state.clazz} toggleModal={this.toggleModalAdd} formation={this.state.formation} formations={formations} idSerlian={this.props.idSerlian} />
                    }
                </div>
        );
    }

}

export default Formations;