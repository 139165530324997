import React, { Component } from "react";
import { PrimaryButton } from "../../Buttons";
import Toast from "../../Toast";
import Autosuggest from "react-autosuggest";
import { TiDelete, TiTimes } from "react-icons/ti";
import { MdAdd } from "react-icons/md";
import SerlianContext from "../../../context";
import { addSkills } from "../../../utils/fetch/fetchSkills";

class AddSkill extends Component {
  static contextType = SerlianContext;

  constructor(props) {
    super(props);
    this.state = {
      toast: "",
      disabledButton: false,
      valueButton: "Ajouter",
      search: "",
      suggestions: [],
      skillId: "",
      newSkill: false,
      skillsToAdd: [],
    };
  }

  componentWillUnmount() {
    clearTimeout(this.state.toastTimer);
  }

  handleChange = (e) => {
    this.setState({ search: e.currentTarget.value });
  };

  getSuggestions = (value) => {
    let inputValue = value.trim().toLowerCase();
    let inputLength = inputValue.length;

    return Promise.resolve(
      inputLength === 0
        ? []
        : this.props.skills.filter(
            (skill) =>
              skill.skill.toLowerCase().slice(0, inputLength) === inputValue
          )
    );
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  getSuggestionValue = (suggestion) => {
    return suggestion.skill;
  };

  renderSuggestion = (suggestion) => <div>{suggestion.skill}</div>;

  onSuggestionsFetchRequested = (e) => {
    this.getSuggestions(e.value).then((suggestions) =>
      this.setState({ suggestions, search: e.value, skillId: "" })
    );
  };

  onSuggestionSelected = (e, { suggestion, suggestionValue }) => {
    if (e.type === "keydown") {
      e.preventDefault();
    }
    let { skillsToAdd } = this.state;
    skillsToAdd.push(suggestion);
    this.setState({ search: "", skillId: "", skillsToAdd });
  };

  addToSkillsToAdd = () => {
    let { skillsToAdd } = this.state;
    let isExist = false;
    const prom = this.props.currentSkills.map((skill) => {
      if (
        skill.id_skill === this.state.skillId ||
        skill.skill.toLowerCase() === this.state.search.toLowerCase()
      ) {
        isExist = true;
      }
    });
    Promise.all(prom).then(() => {
      if (isExist) {
        this.setState({ toast: "EXIST" });
      } else {
        let skill = { id_skill: this.state.skillId, skill: this.state.search };
        let index = skillsToAdd
          .map((s) => {
            return s.skill;
          })
          .indexOf(skill.skill);
        if (skill.skill.length < this.context.maxLength && index === -1) {
          skillsToAdd.push(skill);
          this.setState({ skillsToAdd, search: "", toast: "" });
        } else {
          this.setState({ toast: "DUPLICATE" });
        }
      }
    });
  };

  removeOfSkillsToAdd = (index) => {
    let { skillsToAdd } = this.state;
    skillsToAdd.splice(index, 1);
    this.setState({ skillsToAdd });
  };

  resetField = () => {
    this.setState({ search: "" });
  };

  addSkills = (currentTarget, target) => {
    addSkills(
      currentTarget,
      target,
      this.state.skillsToAdd,
      this.props.serlian,
      (response) => {
        if (response.name === "success") {
          target.reset();
          this.setState({
            valueButton: "Envoyer",
            disabledButton: false,
            toast: "SUCCESS",
            search: "",
            skillId: "",
            skillsToAdd: [],
          });
          this.state.skillsToAdd.map((skill) => {
            if (!skill.id_skill) {
              this.setState({ newSkill: true });
            }
          });
          this.props.toggleModal(currentTarget);
          this.props.updateSkills();
        } else if (response.message === "NOT_ALLOWED") {
          this.setState({
            toast: "NOT_ALLOWED",
            valueButton: "Envoyer",
            disabledButton: false,
          });
        } else {
          this.setState({
            toast: "ERROR",
            valueButton: "Envoyer",
            disabledButton: false,
            skillId: "",
          });
        }

        const toastTimer = setTimeout(
          function () {
            this.setState({ toast: "" });
          }.bind(this),
          8000
        );
        this.setState({ toastTimer });
      }
    );
  };

  handleSubmit = (e) => {
    const target = e.target;
    const currentTarget = e.currentTarget;
    e.preventDefault();
    this.setState({
      valueButton: <div className="loader" />,
      disabledButton: true,
    });
    if (this.state.skillsToAdd.length > 0) {
      this.addSkills(currentTarget, target);
    } else {
      this.setState({
        toast: "EMPTY",
        valueButton: "Envoyer",
        disabledButton: false,
      });
      const toastTimer = setTimeout(
        function () {
          this.setState({ toast: "" });
        }.bind(this),
        8000
      );
      this.setState({ toastTimer });
    }
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      let target = event.target;
      if (target.value !== "") {
        event.preventDefault();
        this.addToSkillsToAdd();
      }
    }
  };

  renderToast = () => {
    switch (this.state.toast) {
      case "SUCCESS":
        return (
          <Toast
            type="success"
            title="Succès"
            message={
              "Les compétences ont bien été ajoutées" +
              (this.state.newSkill
                ? " et certaines sont en cours de validation"
                : "") +
              "."
            }
          />
        );
      case "ERROR":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="Les compétences n'ont pas pu être ajoutées, veuillez réessayer plus tard."
          />
        );
      case "EXIST":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="La compétence est déjà renseignée."
          />
        );
      case "NOT_ALLOWED":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="Vous n'êtes pas autorisé à faire ceci."
          />
        );
      case "DUPLICATE":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="La compétence est déjà en attente d'être ajoutée."
          />
        );
      case "EMPTY":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="Vous devez saisir une compétence."
          />
        );
      default:
        return null;
    }
  };

  renderInputComponent = (inputProps) => {
    return (
      <>
        <input {...inputProps} />
        <button
          onClick={this.resetField}
          type="Reset"
          className={"cross-button" + (this.state.search ? " show" : "")}
        >
          <TiDelete />
        </button>
      </>
    );
  };

  render() {
    const { search, suggestions } = this.state;
    const inputProps = {
      placeholder: "Chercher une compétence",
      value: search,
      className: "form__input",
      onChange: this.handleChange,
      onKeyPress: this.handleKeyPress,
    };
    return (
      <>
        {this.renderToast()}
        <form
          className={this.props.clazz}
          onClick={(e) => this.props.toggleModal(e.target)}
          onSubmit={this.handleSubmit}
        >
          <div className="form--modal__container ">
            <div className="container container--column container--centered">
              <h2>Ajouter une compétence</h2>
              <div className="advice">
                <span>
                  Une compétence n'est pas dans cette liste ? Ajoutez-là, elle
                  s'affichera après validation
                </span>
              </div>
            </div>
            <label className="form__control">
              <div className="form__line">
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  onSuggestionSelected={this.onSuggestionSelected}
                  getSuggestionValue={this.getSuggestionValue}
                  renderSuggestion={this.renderSuggestion}
                  inputProps={inputProps}
                  renderInputComponent={this.renderInputComponent}
                />
                <button
                  className="btn btn--round"
                  type="button"
                  title="Ajouter une compétence"
                  onClick={() => this.addToSkillsToAdd()}
                >
                  <MdAdd />
                </button>
              </div>
            </label>

            {this.state.skillsToAdd.length > 0 && (
              <>
                <ul>
                  {this.state.skillsToAdd.map((skill, index) => (
                    <li className="form__line" key={index}>
                      <button
                        type="button"
                        className="cross--delete"
                        onClick={() => this.removeOfSkillsToAdd(index)}
                      >
                        <TiTimes />
                      </button>
                      {skill.skill}
                    </li>
                  ))}
                </ul>
                <div className="container container--centered">
                  <PrimaryButton
                    id="btn-confirm"
                    value={this.state.valueButton}
                  />
                </div>
              </>
            )}
          </div>
        </form>
      </>
    );
  }
}

export default AddSkill;
