import React from "react";
import { PrimaryButton } from "../../Buttons";
import { TiDelete } from "react-icons/ti";

const EditInformations = (props) => {
  const currentSerlian = { ...props.currentSerlian };
  const clazz = props.clazz;

  return (
    <form
      className={clazz}
      onClick={(e) => props.toggleModal(e.target)}
      onSubmit={props.handleSubmit}
    >
      <div className="form--modal__container  overflow-auto">
        <div className="container container--column container--centered">
          <h2>Editer les informations</h2>
        </div>
        <label className="form__control">
          <span>Agence</span>
          <select
            className="form__input"
            name="city"
            onChange={props.handleChange}
            value={currentSerlian.city || ""}
          >
            <option value="">Selectionner une option</option>
            <option value="Agence de Niort">Agence de Niort</option>
            <option value="Agence de Poitiers">Agence de Poitiers</option>
          </select>
        </label>
        <label className="form__control">
          <span>
            Photo{" "}
            {props.errorPicture && (
              <em>L'image doit être de dimension carrées (ex. 500x500)</em>
            )}
          </span>
          <input
            type="file"
            onChange={props.handleChange}
            key={props.inputFileKey}
            className={
              props.errorPicture
                ? "form__input form__input--error"
                : "form__input"
            }
            accept="image/jpeg"
          />
          <button
            onClick={props.resetField}
            type="reset"
            className={
              props.picture
                ? "cross-button reset-edit show"
                : "cross-button reset-edit"
            }
          >
            <TiDelete />
          </button>
        </label>
        <label className="form__control">
          <span>Lien présentation sur le blog</span>
          <input
            type="text"
            className="form__input"
            name="blog_article"
            onChange={props.handleChange}
            value={currentSerlian.blog_article || ""}
          />
        </label>
        <label className="form__control">
          <span>Lien linkedin</span>
          <input
            type="text"
            className="form__input"
            name="linkedin"
            onChange={props.handleChange}
            value={currentSerlian.linkedin || ""}
          />
        </label>
        <label className="form__control">
          <span>Lien github</span>
          <input
            type="text"
            className="form__input"
            name="github"
            onChange={props.handleChange}
            value={currentSerlian.github || ""}
          />
        </label>
        <label className="form__control">
          <span>Lien twitter</span>
          <input
            type="text"
            className="form__input"
            name="twitter"
            onChange={props.handleChange}
            value={currentSerlian.twitter || ""}
          />
        </label>
        <div className="container container--column container--centered">
          <PrimaryButton
            disabled={props.errorMailFormat || props.errorPicture}
            id="btn-confirm"
            value={props.valueButton}
          />
        </div>
      </div>
    </form>
  );
};

export default EditInformations;
