import React, {Component} from "react";
import SerlianContext from "../../context";
import moment from "moment";
import "moment/locale/fr";
import Toast from "../../components/toast/Toast";
import {MdDeleteSweep} from "react-icons/md";
import {IoMdTrash} from "react-icons/io";
import {Redirect} from "react-router-dom";
import {TiDelete} from 'react-icons/ti';
import {getUserMissions} from "../../utils/fetch/fetchMissions";
import {deleteMission, updateOrAddMission} from "../../utils/fetch/fetchMissionsAdmin";
import Autosuggest from 'react-autosuggest';
import "./gestionMissions.scss";

moment().locale("fr");

class GestionMissions extends Component {

    static contextType = SerlianContext;

    constructor(props) {
        super(props);
        this.state = {
            missions : [],
            toast : "",
            linesToDelete: [],
            selectedMission: 0,
            isEdit: false,
            sort: 1,
            idSerlian: "",
            nbLinesToAdd: 0,
            serlians: [],
            searchEdit: "",
            errorMission: false
        }
    }

    componentDidMount() {
        if(window.location.pathname === "/missions/add") {
            this.addMissionField();
        }
        else {
            this.setState({isEdit: true});
        }

        this.unlisten = this.props.history.listen((location) => {
            if(location.pathname === "/missions/add") {
                this.setState({isEdit: false, missions: []}, () => this.addMissionField());
            }
            else {
                this.setState({isEdit: true, missions: []});
            }
        })
    }

    componentWillUnmount() {
        this.unlisten();
        clearTimeout(this.state.toastTimer);
    }

    getActiveSerlians = (serlians) => {
        serlians = serlians.filter((elt) => !elt.archive);
        return serlians;
    };

    sortSerlian = (serlians) => {
        return serlians.sort((a, b) =>
            this.state.sort * a.firstname.localeCompare(b.firstname));
    };

    onChangeMode = (e) => {
        if(!e.target.checked) {
            this.props.history.push('/missions/add');
        }
        else {
            this.props.history.push('/missions/gestion');
        }
    };

    getSuggestions = value => {
        let inputValue = this.noAccent(value.trim().toLowerCase());
        let inputLength = inputValue.length;

        return Promise.resolve(inputLength === 0 ? [] : this.context.serlians.filter(serlian =>
            this.noAccent(serlian.firstname.toLowerCase() + " " +  serlian.lastname.toLowerCase()).startsWith(inputValue)
                || this.noAccent(serlian.lastname.toLowerCase() + " " +  serlian.firstname.toLowerCase()).startsWith(inputValue)
        ));
    };

    onSuggestionsClearRequested = () => {
        this.setState({serlians: []});
    };

    getSuggestionValue = (serlian) => {
        return serlian.id;
    };

    renderSuggestion = serlian => (
        <div>
            {serlian.firstname} {serlian.lastname}
        </div>
    );

    onSuggestionsFetchRequested = (e) => {
        this.getSuggestions(e.value).then(suggestions => this.setState({serlians: suggestions}));
    };

    onSuggestionSelected = (e, suggestion, index) => {
        if(!this.state.isEdit) {
            let {missions} = this.state;
            missions[index].id_user = suggestion.suggestion.id;
            missions[index].search = suggestion.suggestion.firstname + " " + suggestion.suggestion.lastname;
            this.setState({missions});
        }
        else {
            this.getMissionsOfUser(suggestion.suggestion.id);
            this.setState({searchEdit: suggestion.suggestion.firstname + " " + suggestion.suggestion.lastname})
        }
    };

    getMissionsOfUser = (id) => {
        getUserMissions(id, (missions) => {
            this.setState({missions, idSerlian: id});
        });
    };

    handleChangeSerlian = (e) =>  {
        let target = e.target;
        if(target.value) {
            this.setState({searchEdit: target.value})
        }
        else {
            this.setState({missions: [], searchEdit: target.value})
        }
    };

    addMissionField = () => {
        let mission;
        let {nbLinesToAdd} = this.state;
        if(!this.state.isEdit) {
            mission = {
                id_user: this.state.missions.length > 0 ? this.state.missions[this.state.missions.length - 1].id_user : "",
                search: this.state.missions.length > 0 ? this.state.missions[this.state.missions.length - 1].search : "",
                current: false,
                client_name: "",
                begin_time: moment().format("YYYY-MM-DD"),
                end_time: null,
                workplace: "",
            };
        } else {
            mission = {
                id_user: this.state.idSerlian,
                current: false,
                client_name: "",
                begin_time: moment().format("YYYY-MM-DD"),
                end_time: null,
                workplace: ""
            };
            nbLinesToAdd++;
        }
        this.state.missions.push(mission);

        this.setState({missions: this.state.missions, nbLinesToAdd});
    };

    // Permet de retourner une liste d'année à partir de/jusqu'à une date
    years = (back, year, after) => {
        return Array.from({length: back}, (v, i) => after ? year.getFullYear() + i : year.getFullYear() - back + i + 1);
    };

    noAccent = (string) => {
        let accent = [
            /[\300-\306]/g, /[\340-\346]/g, // A, a
            /[\310-\313]/g, /[\350-\353]/g, // E, e
            /[\314-\317]/g, /[\354-\357]/g, // I, i
            /[\322-\330]/g, /[\362-\370]/g, // O, o
            /[\331-\334]/g, /[\371-\374]/g, // U, u
            /[\321]/g, /[\361]/g, // N, n
            /[\307]/g, /[\347]/g, // C, c
        ];
        let noAccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c'];
        accent.map( ( acc , i ) => {
            string = string.replace(acc, noAccent[i])
        });
        return string;
    };

    handleChange = (event, indexMission) => {
        let source = event.target;
        let name = source.getAttribute("name");
        let indexInput = source.getAttribute("dataindex");
        let missions = this.state.missions;
        let maxLen = this.context.maxLength;
        switch(name){
            case "serlian" :
                missions[indexInput].search = source.value;
                this.setState({missions});
                break;

            case "current" :
                missions[indexMission].current = !missions[indexMission].current;
                missions[indexMission].end_time = null;
                this.setState({missions});
                break;

            case "client_name" :
                if(source.value.length < maxLen) missions[indexMission].client_name = source.value;
                this.setState({missions});
                break;

            case "workplace" :
                if(source.value.length < maxLen) missions[indexMission].workplace = source.value;
                this.setState({missions});
                break;

            case "begin_time_mobile" :
                missions[indexMission].begin_time = moment(source.value).format("YYYY-MM-DD");
                this.setState({missions});
                break;

            case "end_time_mobile" :
                missions[indexMission].end_time = moment(source.value).format("YYYY-MM-DD");
                this.setState({missions});
                break;

            case "begin_time_month" :
                missions[indexMission].begin_time = moment(moment(missions[indexMission].begin_time).format("MM/YYYY").substr(3) + "-" + source.value).format("YYYY-MM-DD");
                this.setState({missions});
                break;

            case "begin_time_year" :
                missions[indexMission].begin_time = moment(source.value + "-" + moment(missions[indexMission].begin_time).format("MM/YYYY").substr(0, 2)).format("YYYY-MM-DD");
                this.setState({missions});
                break;

            case "end_time_month" :
                if(missions[indexMission].end_time !== null) {
                    missions[indexMission].end_time = moment(moment(missions[indexMission].end_time).format("MM/YYYY").substr(3) + "-" + source.value).format("YYYY-MM-DD");
                }
                else {
                    missions[indexMission].end_time = moment(moment(missions[indexMission].begin_time).format("MM/YYYY").substr(3) + "-" + source.value).format("YYYY-MM-DD");

                }
                this.setState({missions});
                break;

            case "end_time_year" :
                if(missions[indexMission].end_time !== null) {
                    missions[indexMission].end_time = moment(source.value + "-" + moment(missions[indexMission].end_time).format("MM/YYYY").substr(0, 2)).format("YYYY-MM-DD");
                }
                else {
                    missions[indexMission].end_time = moment(source.value + "-" + moment(missions[indexMission].begin_time).format("MM/YYYY").substr(0, 2)).format("YYYY-MM-DD");

                }
                this.setState({missions});
                break;

            case "end_time" :
                missions[indexMission].end_time = moment(source.value).format('YYYY-MM-DD');
                this.setState({missions});
                break;

            default :
                break;
        }
    };

    // Ajoute les lignes à supprimer dans un tableau
    deleteLines = (e, mission) => {
        let {linesToDelete} = this.state;
        if(e.target.checked) {
            linesToDelete.push(mission);
        }
        else {
            linesToDelete.splice(linesToDelete.indexOf(mission), 1);
        }
        this.setState({linesToDelete})

    };

    // Supprime les lignes qui sont dans le tableau des lignes à supprimer
    onClickDeleteLines = (e) => {
        e.preventDefault();
        let {missions, linesToDelete} = this.state;

        linesToDelete.map((line) => {
            missions.splice(missions.indexOf(line), 1);
        });

        this.setState({missions: missions, linesToDelete: []});
        if(missions.length === 0) {
            this.addMissionField();
        }
        document.querySelectorAll("input.input-supp").forEach(element => {
            element.checked = false;
        });
    };

    deleteMission = (id, id_user) => {
        if(confirm("Voulez vous vraiment supprimer cette mission ?")) {
            deleteMission(id, (response) => {
                if (response.name === "success") {
                    this.setState({toast: "SUCCESSDEL"});
                } else {
                    this.setState({toast: "ERRORDEL"});
                }
                let toastTimer = setTimeout(function () {
                    this.setState({toast: ""});
                }.bind(self), 8000);
                this.setState({toastTimer});

                this.getMissionsOfUser(id_user);
            });
        }
    };

    updateOrAddMission = (update, missions) => {
        updateOrAddMission(update, missions, (response) => {
            if(response.name === "success") {
                this.setState({toast : "SUCCESS", nbLinesToAdd: 0});
            }
            else {
                this.setState({toast: "ERROR"});
            }
            let toastTimer = setTimeout(function () {
                this.setState({toast: ""});
            }.bind(this), 8000);
            this.setState({toastTimer});
            if(!this.state.isEdit) {
                this.setState({missions: []});
                this.addMissionField();
            }
        })
    }

    handleDeleteLine = (event, idMission, idUser, index) => {
        event.preventDefault();
        if (idMission !== undefined){
            this.deleteMission(idMission, idUser);
        } else {
            this.state.missions.splice(index, 1);
            this.setState({missions : this.state.missions});
        }
    };

    handleSubmitSlice = (event) => {
        if(!this.state.isEdit){
            this.handleSubmit(event, false, this.state.missions);
        } else {
            let {missions, nbLinesToAdd} = this.state;
            let endIndex = missions.length - nbLinesToAdd;
            let updateMissions = missions.slice(0,endIndex);
            let addMissions = missions.slice(endIndex, missions.length);
            if(updateMissions.length > 0)
                this.handleSubmit(event, true, updateMissions);
            if(addMissions.length > 0)
                this.handleSubmit(event, false, addMissions);
        }
    }

    handleSubmit = (event, update, missions) => {
        event.preventDefault();
        let errorMission = false;
        missions.length > 0 && missions.map((m, index) => {
            if(m.id_user === "" || m.id_user === undefined){
                missions.splice(index, 1);
                errorMission = true;
            }
        })
        this.setState({errorMission}, () => {
            if(missions.length > 0){
                this.updateOrAddMission(update, missions);
            } else {
                this.setState({toast: "ERROR"});
                let toastTimer = setTimeout(function () {
                    this.setState({toast: ""});
                }.bind(this), 8000);
                this.setState({toastTimer});
                this.addMissionField();
            }
        });
    }

    resetField = (index) => {
        let {missions} = this.state;
        if(this.state.isEdit) {
            missions = [];
        }
        else {
            missions[index].search = "";
            missions[index].id_user = "";
        }
        this.setState({searchEdit: "", missions});
    };

    renderInputComponent = (inputProps) => {
        let index = null;
        if(inputProps.dataindex >= 0) {
            index = inputProps.dataindex
        }
        return (
            <>
                <input {...inputProps}/>
                <button onClick={() => this.resetField(index)} type="button" className={"crossButton" + (inputProps.value ? " show" : "")}>
                    <TiDelete/>
                </button>
            </>
        )
    };

    renderToast = () => {
        switch (this.state.toast) {
            case "SUCCESS":
                return <Toast type="success"
                              title="Succès"
                              message={"Les missions ont bien été " + (this.state.isEdit ? "modifiées." :"ajoutées.") + (this.state.errorMission ? " Certaine(s) ligne(s) n'avaient pas un Serlien valide" : "")}/>;
            case "ERROR" :
                return <Toast type="error"
                              title="Erreur"
                              message={"Les missions n'ont pas pu être " + (this.state.isEdit ? "modifiées." :"ajoutées") + " veuillez réessayer plus tard." + (this.state.errorMission ? " Certaine(s) lignes n'avaient pas un Serlien valide" : "")}/>;
            case "SUCCESSDEL" :
                return <Toast type="success"
                              title="Succès"
                              message={"La missions a bien été supprimée."}/>;
            case "ERRORDEL" :
                return <Toast type="error"
                              title="Erreur"
                              message={"La mission n'a pas pu être supprimée, veuillez réessayer plus tard."}/>;
            default:
                return null;
        }
    };

    render() {
        const screen = this.context.isMobile;
        const {selectedMission, isEdit, serlians, searchEdit} = this.state;
        const inputPropsEdit = {
            placeholder: "Chercher un serlien",
            value: searchEdit,
            name: "serlian",
            className: "input-serlian",
            onChange: this.handleChangeSerlian
        };
        let inputPropsAddMobile = {
            placeholder: "Chercher un serlien",
            value: this.state.missions.length > 0 ? this.state.missions[0].search: "",
            name: "serlian",
            className: "form-input",
            dataindex: 0,
            onChange: this.handleChange
        };
        if(this.context.serlianConnected.role !== "admin") {
            return (<Redirect to="/" />)
        }
        return (
            <div className="add-mission-container">
                {this.renderToast()}
                <div className="header-add-mission">
                    <h2 className="test">{isEdit ? "Edition": "Ajout"} de mission</h2>
                    <em>Les champs suivis d'une étoile <u>doivent</u> être remplis</em>
                    {!screen && !isEdit && <em>Veillez également à supprimer les lignes inutiles</em>}
                    {isEdit && <em>Sélectionnez un serlien pour modifier ses missions</em>}
                </div>
                {
                    isEdit &&
                    <div className="selects-gestion-mission">
                        <label className="form-control">
                            Serlien
                            <Autosuggest
                                suggestions={serlians}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                onSuggestionSelected={(e, suggestion) => {this.onSuggestionSelected(e, suggestion)}}
                                getSuggestionValue={this.getSuggestionValue}
                                renderSuggestion={this.renderSuggestion}
                                inputProps={inputPropsEdit}
                                renderInputComponent={this.renderInputComponent}
                            />
                        </label>
                        {
                            this.state.missions.length > 0 && screen &&
                            <label className="form-control">
                                Mission
                                <select className="form-input" onChange={(e) => this.setState({selectedMission: e.target.value})}>
                                    {
                                        isEdit && this.state.missions.map((mission, index) => {
                                            return <option value={index} key={index}> {mission.client_name} - {moment(mission.begin_time).format("MM/YYYY")}</option>
                                        })
                                    }
                                </select>
                            </label>
                        }

                    </div>
                }
                <label className="edit-control">
                    <span>Mode Edition</span>
                    <div className="switch">
                        <input type="checkbox" checked={this.state.isEdit} onChange={this.onChangeMode} />
                        <span className="slider round" />
                    </div>
                </label>
                <form className="form" onSubmit={this.handleSubmitSlice}>
                    {
                        screen ?
                            <div className="form-mobile">
                                {/* ---------------------------------- MOBILE ---------------------------------- */}
                                {
                                    this.state.missions.length > 0 &&
                                    <>
                                        {
                                            !isEdit &&
                                            <label className="form-control">
                                                <span>Serlien *</span>
                                                <Autosuggest
                                                    suggestions={serlians}
                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                    onSuggestionSelected={(e, suggestion) => {this.onSuggestionSelected(e, suggestion, 0)}}
                                                    getSuggestionValue={this.getSuggestionValue}
                                                    renderSuggestion={this.renderSuggestion}
                                                    inputProps={inputPropsAddMobile}
                                                    renderInputComponent={this.renderInputComponent}
                                                />
                                            </label>
                                        }
                                        <label className="form-control">
                                            <span>Mission en cours ? </span>
                                            <input name="current" key="current" type="checkbox"
                                                   onChange={(e) => this.handleChange(e, selectedMission)} checked={this.state.missions[selectedMission].current}/>
                                        </label>
                                        <label className="form-control">
                                            <span>Client * </span>
                                            <input name="client_name" key="client_name"
                                                   onChange={(e) => this.handleChange(e, selectedMission)}
                                                   placeholder="Nom du client"
                                                   className="form-input" value={this.state.missions[selectedMission].client_name}/>
                                        </label>
                                        <label className="form-control">
                                            <span>Lieu de travail</span>
                                            <input className="form-input" name="workplace"
                                                   onChange={(e) => this.handleChange(e, selectedMission)}
                                                   placeholder="Lieu de travail" value={this.state.missions[selectedMission].workplace ? this.state.missions[selectedMission].workplace : ""}/>
                                        </label>
                                        <label className="form-control">
                                            <span>Date de début * </span>
                                            <input name="begin_time_mobile" key="begin_time" type="month"
                                                   value={moment(this.state.missions[selectedMission].begin_time).format("YYYY-MM")}
                                                   onChange={(e) => this.handleChange(e, selectedMission)}
                                                   className="form-input"/>
                                        </label>
                                        <label className="form-control">
                                            <span>Date de fin </span>
                                            <input name="end_time_mobile" type="month"
                                                   onChange={(e) => this.handleChange(e, selectedMission)}
                                                   min={moment(this.state.missions[selectedMission].begin_time).format("YYYY-MM")}
                                                   value={moment(this.state.missions[selectedMission].end_time).format("YYYY-MM")}
                                                   disabled={this.state.missions[selectedMission].current}
                                                   className="form-input"/>
                                        </label>
                                    </>
                                }
                                {
                                    this.state.missions.length > 0 &&
                                        <div className="container-button">
                                            {isEdit && <button type="button" className="btn-round" onClick={() => this.deleteMission(this.state.missions[selectedMission].id, this.state.missions[selectedMission].id_user)}><IoMdTrash/></button>}
                                            <button className="primary-button mobile-button" type="submit">{this.state.isEdit ? "Mettre à jour" : "Envoyer"}</button>
                                        </div>
                                }
                            </div>
                        :
                            <>
                                {/* ---------------------------------- DESKTOP ---------------------------------- */}
                                <table className="add-many-mission" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            {!isEdit && <th>Nom du Serlien *</th>}
                                            <th>En cours ?</th>
                                            <th>Nom du client *</th>
                                            <th>Lieu de travail</th>
                                            <th>Date de début *</th>
                                            <th>Date de fin</th>
                                            <th>
                                                {
                                                    !isEdit ?
                                                        <MdDeleteSweep style={{fontSize: "24px"}}/>
                                                    :
                                                        <IoMdTrash style={{fontSize: "24px"}}/>
                                                }
                                                {
                                                    this.state.linesToDelete.length > 0 &&
                                                        <button className="primary-button" onClick={this.onClickDeleteLines}>Suppr({this.state.linesToDelete.length})</button>
                                                }
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.missions && this.state.missions.map((m, index) => {
                                            const inputPropsM = {
                                                placeholder: "Chercher un serlien",
                                                value: m.search,
                                                name: "serlian",
                                                className: "form-input",
                                                dataindex: index,
                                                onChange: this.handleChange
                                                // onKeyPress: this.handleKeyPress
                                            };
                                            return(
                                                <tr style={{position: "relative"}} key={index + "mission"}>
                                                    {
                                                        !isEdit &&
                                                        <td className="form-control">
                                                            <Autosuggest
                                                                suggestions={serlians}
                                                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                                onSuggestionSelected={(e, suggestion) => {this.onSuggestionSelected(e, suggestion, index)}}
                                                                getSuggestionValue={this.getSuggestionValue}
                                                                renderSuggestion={this.renderSuggestion}
                                                                inputProps={inputPropsM}
                                                                renderInputComponent={this.renderInputComponent}
                                                            />
                                                        </td>
                                                    }
                                                    <td>
                                                        <input className="form-input" name="current" type="checkbox"
                                                               onChange={(e) => this.handleChange(e, index)}
                                                               checked={m.current}/>
                                                    </td>
                                                    <td className="form-control">
                                                        <input className="form-input" name="client_name"
                                                               onChange={(e) => this.handleChange(e, index)}
                                                               placeholder="Nom du client" value={m.client_name} required/>
                                                    </td>
                                                    <td className="form-control">
                                                        <input className="form-input" name="workplace"
                                                               onChange={(e) => this.handleChange(e, index)}
                                                               placeholder="Lieu de travail" value={m.workplace ? m.workplace : ""}/>
                                                    </td>
                                                    <td className="form-control">
                                                        <select className="form-input select-month" name="begin_time_month" onChange={(e) => this.handleChange(e, index)} value={moment(m.begin_time).format("MM/YYYY").substr(0, 2)}>
                                                            {
                                                                moment.months().map((month, index) => {
                                                                    return <option key={index} value={index + 1 > 9 ? index + 1 : "0" + (index + 1)}>{month}</option>
                                                                })
                                                            }
                                                        </select>
                                                        <select className="form-input select-year" name="begin_time_year" onChange={(e) => this.handleChange(e, index)} value={moment(m.begin_time).format("MM/YYYY").substr(3)}>
                                                            {
                                                                this.years(30, new Date(), false).map((year, index) => {
                                                                    return <option key={index} value={year}>{year}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </td>
                                                    <td className="form-control">
                                                        <select className="form-input select-month" name="end_time_month" onChange={(e) => this.handleChange(e, index)} value={m.end_time ? moment(m.end_time).format("MM/YYYY").substr(0, 2) : ""} disabled={m.current}>
                                                            <option value="">----</option>
                                                            {
                                                                moment.months().map((month, index) => {
                                                                    return <option key={index} value={index + 1 > 9 ? index + 1 : "0" + (index + 1)}>{month}</option>
                                                                })
                                                            }
                                                        </select>
                                                        <select className="form-input select-year" name="end_time_year" onChange={(e) => this.handleChange(e, index)} value={m.end_time ? moment(m.end_time).format("MM/YYYY").substr(3) : ""} disabled={m.current}>
                                                            <option value="">----</option>
                                                            {
                                                                this.years(30, new Date(m.begin_time),  true).map((year, index) => {
                                                                    return <option key={index} value={year}>{year}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </td>
                                                    <td>
                                                    {
                                                        !isEdit ?
                                                                <input
                                                                    className="form-input input-supp"
                                                                    name="supp" type="checkbox"
                                                                    onChange={(event) => this.deleteLines(event, m)}/>
                                                        :
                                                            <button type="button" className="btn-round" onClick={(event) => this.handleDeleteLine(event, m.id, m.id_user, index)}><IoMdTrash/></button>
                                                    }
                                                    </td>
                                                </tr>)
                                            }
                                        )
                                    }
                                    </tbody>
                                </table>
                                <div className="container-button">
                                    {
                                        (this.state.missions.length > 0 || !this.state.isEdit) &&
                                        <button className="primary-button" type="button" onClick={this.addMissionField}>
                                            Ajouter une ligne
                                        </button>
                                    }
                                    {
                                        this.state.missions.length > 0 &&
                                        <button className="primary-button" type="submit">
                                            {this.state.isEdit ? this.state.nbLinesToAdd > 0 ? "Mettre à jour & Envoyer": "Mettre à jour" : "Envoyer"}
                                        </button>
                                    }
                                </div>
                            </>
                    }
                </form>
            </div>
        );
    }
}

export default GestionMissions;