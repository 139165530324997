import React, { useState, useEffect } from "react";
import { SERLIAN_DEFAULT_PICTURE } from "../../../utils/Images";
import { changeSerlianListVisibility } from "../../../utils/fetch/fetchSerlianListVisibility";

const SerlianListCardItem = ({ serlian, listId, initialCardVisibility }) => {
  const [cardVisible, setCardVisible] = useState(initialCardVisibility);
  const [photo, setPhoto] = useState();

  useEffect(() => {
    setPhoto(cardVisible ? serlian.picture : SERLIAN_DEFAULT_PICTURE);
  }, [cardVisible, serlian.picture]);

  const turnCard = async (serlian) => {
    const updatedCardVisibility = !cardVisible;
    setCardVisible(updatedCardVisibility);
    try {
      await changeSerlianListVisibility(
        serlian.id,
        listId,
        updatedCardVisibility
      );
    } catch (error) {
      console.error("Error updating card visibility:", error);
    }
  };

  return (
    <div
      className="card card--serlian"
      key={serlian.id}
      onClick={() => turnCard(serlian)}
    >
      <img className="img" src={photo} alt="photo" />
      <div className="card__insert">
        <h4>
          {serlian.firstname} {serlian.lastname}
        </h4>
      </div>
    </div>
  );
};

export default SerlianListCardItem;
