import React, {Component} from "react";
import "./errorSerlianProfil.scss";

class ErrorSerlianProfil extends Component {
    render() {
        return (
            <div className="error-page">
                <h2>Oups...</h2>
                <p>Il semble que vous n'ayez pas de profil sur le trombinoscope. Pour y remedier, veuillez contacter Anne Roquain par mail :</p>
                <p>anne.roquain@serli.com</p>
            </div>
        );
    }
}

export default ErrorSerlianProfil;