import React, { useState, useEffect, useContext } from "react";
import unorm from "unorm";
import SerlianContext from "../context";
import {
  getAllClients,
  getSerliansByClient,
} from "../utils/fetch/fetchClients";

const SearchSerlianByClient = ({ setSerlians }) => {
  const serlianContext = useContext(SerlianContext);
  const serlians = serlianContext.serlians;

  const [allClients, setAllClients] = useState([]);
  const [uniqueClients, setUniqueClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [clientNameMap, setClientNameMap] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const clients = await getAllClients();

      setAllClients(clients);

      const clientNameMap = {};
      const uniqueClientNames = Array.from(
        new Set(
          clients.map((client) => {
            const normalizedClientName = unorm.nfc(
              client.client_name.toUpperCase().trim()
            );
            clientNameMap[normalizedClientName] = client.client_name;
            return normalizedClientName;
          })
        )
      );

      setClientNameMap(clientNameMap);
      setUniqueClients(uniqueClientNames);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchSerliansByClient = async () => {
      if (selectedClients.length > 0) {
        try {
          const allSerliansFromSelectedClients = await Promise.all(
            selectedClients.map(async (selectedClient) => {
              const originalClientName = clientNameMap[selectedClient];
              const serliansOfClient = await getSerliansByClient(
                originalClientName
              );
              return serliansOfClient;
            })
          );

          const serliansFromSelectedClients =
            allSerliansFromSelectedClients.flat();

          const updatedSerlianContextSet = new Set();

          serliansFromSelectedClients.forEach((serlian) => {
            serlians.forEach((existingSerlian) => {
              if (existingSerlian.id === serlian.id) {
                updatedSerlianContextSet.add(existingSerlian);
              }
            });
          });

          const updatedSerlianContext = Array.from(updatedSerlianContextSet);

          setSerlians(updatedSerlianContext);
        } catch (error) {}
      } else {
        setSerlians(serlians);
      }
    };

    fetchSerliansByClient();
  }, [selectedClients]);

  useEffect(() => {
    if (serlianContext.serliansToShow.length === serlians.length) {
      setSelectedClients([]);
    }
  }, [serlianContext.serliansToShow, serlians]);

  const searchByClient = (clientName) => {
    if (selectedClients.includes(clientName)) {
      setSelectedClients(
        selectedClients.filter((company) => company !== clientName)
      );
    } else {
      setSelectedClients([...selectedClients, clientName]);
    }
  };

  return (
    <div className="container container--centered">
      {uniqueClients.map((clientName) => (
        <div
          key={clientName}
          className={
            selectedClients.includes(clientName)
              ? "btn btn--light-blue btn--light-blue-selected"
              : "btn btn--light-blue"
          }
          onClick={() => searchByClient(clientName)}
        >
          <p className="btn__text">{clientName}</p>
        </div>
      ))}
    </div>
  );
};

export default SearchSerlianByClient;
