import React, {Component} from "react";
import {updateFormations} from "../../../utils/fetch/fetchFormations";
import Toast from "../../toast/Toast";
import {PrimaryButton} from "../../buttons/Buttons";

class AddFormation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            toast: "",
            valueButton: "Ajouter",
            disabledButton: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.formation !== "" && prevProps.formation !== this.props.formation) {
            this.setState({title: this.props.formation, valueButton: "Mettre à jour"})
        }
        else if(this.props.formation === "" && prevProps.formation !== this.props.formation) {
            this.setState({title: "", valueButton: "Ajouter"});
        }
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    handleSubmit = (e) => {
        e.preventDefault();

        let formations = this.props.formations ? this.props.formations : [];
        let {title} = this.state;
        let index = formations.indexOf(this.props.formation);

        this.setState({valueButton: <div className="loader"/>, disabledButton: true});

        if((formations.includes(title) && index === -1) || (formations.includes(title) && index >= 0 && this.props.formation !== title)) {
            this.setState({toast: "EXIST", valueButton: index >=0 ? "Mettre à jour" : "Ajouter", disabledButton: false});
        }
        else if(!title) {
            this.setState({toast: "EMPTY", valueButton: index >=0 ? "Mettre à jour" : "Ajouter", disabledButton: false});
        }
        else {
            if(index >= 0) {
                formations[index] = title;
            }
            else {
                formations.push(title);
            }
            updateFormations(formations, this.props.idSerlian)
                .then(response => {
                    if (response.name === "success") {
                        this.setState({toast: "SUCCESS", title: ""});
                    }
                    if (response.message && response.message === "NOT_ALLOWED") {
                        this.setState({toast: "NOT_ALLOWED"});
                    }
                    this.setState({valueButton: index >=0 ? "Mettre à jour" : "Ajouter", disabledButton: false});
                })
        }
        const toastTimer = setTimeout(function () {
            this.setState({toast: ""});
        }.bind(this), 8000);
        this.setState({toastTimer});
    };

    renderToast = () => {
        switch (this.state.toast) {
            case "SUCCESS":
                return <Toast type="success"
                              title="Succès"
                              message="La formation a bien été ajoutée."/>;
            case "ERROR" :
                return <Toast type="error"
                              title="Erreur"
                              message="La formation n'a pas pu être ajoutée, veuillez réessayer plus tard."/>;
            case "EXIST":
                return <Toast type="error"
                              title="Erreur"
                              message="La formation est déjà renseignée."/>;
            case "NOT_ALLOWED":
                return <Toast type="error"
                              title="Erreur"
                              message="Vous n'êtes pas autorisé à faire ceci."/>;
            case "EMPTY":
                return <Toast type="error"
                              title="Erreur"
                              message="Vous devez saisir une formation."/>;
            default:
                return null;
        }
    };


    render() {
        const {title} = this.state;
        return (
            <form className={this.props.clazz} onClick={(e) => this.props.toggleModal(e.target)} onSubmit={this.handleSubmit}>
                {this.renderToast()}
                <div className="modal-add-container">
                    <div className="form-header">
                        <h3>{this.props.formation !== "" ? "Modifier" : "Ajouter"} une formation</h3>
                    </div>

                    <label className="form-control">
                        <span>Titre</span>
                        <input className="form-input" type="text" name="title" value={title} onChange={this.handleChange}/>
                    </label>

                    <PrimaryButton id="btn-confirm" value={this.state.valueButton} disabled={this.state.disabledButton}/>
                </div>
            </form>
        );
    }
}

export default AddFormation;