import React, { Component } from "react";
import { MdAdd } from "react-icons/md";
import { FiEdit3 } from "react-icons/fi";
import { IoMdTrash } from "react-icons/io";
import moment from "moment";
import AddConference from "./AddConference";
import Toast from "../../Toast";
import {
  getConferences,
  addConference,
  deleteConference,
  updateConference,
} from "../../../utils/fetch/fetchConference";

moment.locale("fr");

class Conferences extends Component {
  constructor(props) {
    super(props);

    this.state = {
      conferences: [],
      clazz: "form--modal",
      editing: false,
      conf: {
        place: "",
        title: "",
        year: moment().format("YYYY"),
        video_link: "",
        article_link: "",
      },
      idSerlian: this.props.idSerlian,
      toast: "",
    };
  }

  componentDidMount() {
    this.getConferences();
  }

  getConferences = () => {
    getConferences(this.state.idSerlian, (conferences) => {
      this.setState({ conferences });
    });
  };

  addConference = (conference) => {
    addConference(conference, this.state.idSerlian, (response) => {
      if (response.name === "success") {
        this.setState({ clazz: "form--modal", toast: "SUCCESSADD" });
      } else {
        this.setState({ clazz: "form--modal", toast: "ERRORADD" });
      }

      this.getConferences();
      const toastTimer = setTimeout(() => {
        this.setState({ toast: "" });
      }, 8000);
      this.setState({ toastTimer });
    });
  };

  deleteConference = (conference) => {
    deleteConference(conference, (response) => {
      if (response.name === "success") {
        this.setState({ toast: "SUCCESSDEL" });
      } else {
        this.setState({ toast: "ERRORDEL" });
      }
      this.getConferences();
    });
  };

  updateConference = (conference) => {
    updateConference(conference, (response) => {
      if (response.name === "success") {
        this.setState({ clazz: "form--modal", toast: "SUCCESSUPDATE" });
      } else {
        this.setState({ clazz: "form--modal", toast: "ERRORUPDATE" });
      }

      this.getConferences();
      const toastTimer = setTimeout(() => {
        this.setState({ toast: "" });
      }, 8000);
      this.setState({ toastTimer });
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.idSerlian !== prevProps.idSerlian) {
      this.setState({ idSerlian: this.props.idSerlian }, () =>
        this.getConferences()
      );
    }
  }

  toggleModalAdd = (target) => {
    if (target.classList.contains("toggle-add")) {
      this.setState({ clazz: "form--modal" });
    }
  };

  handleAdd = () => {
    let conf = {
      place: "",
      title: "",
      year: moment().format("YYYY"),
      video_link: "",
      article_link: "",
    };
    this.setState({ clazz: "form--modal toggle-add", editing: false, conf });
  };

  handleEdit = (conf) => {
    conf.year = moment(conf.year).format("YYYY");
    this.setState({ conf, clazz: "form--modal toggle-add" });
  };

  renderToast = () => {
    switch (this.state.toast) {
      case "SUCCESSADD":
        return (
          <Toast
            type="success"
            title="Succès"
            message={"La conférence a bien été ajoutée."}
          />
        );
      case "ERRORADD":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="La conférence n'a pas pu être ajoutée, veuillez réessayer plus tard."
          />
        );
      case "SUCCESSDEL":
        return (
          <Toast
            type="success"
            title="Succès"
            message={"La conférence a bien été supprimée."}
          />
        );
      case "ERRORDEL":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="La conférence n'a pas pu être supprimée, veuillez réessayer plus tard."
          />
        );
      case "SUCCESSUPDATE":
        return (
          <Toast
            type="success"
            title="Succès"
            message={"La conférence a bien été mise à jour."}
          />
        );
      case "ERRORUPDATE":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="La conférence n'a pas pu être mise à jour, veuillez réessayer plus tard."
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { conferences } = this.state;
    return (
      <div className="section section--conferences">
        {this.renderToast()}
        <div className="section__header">
          <h3 className="section__header__title">Conférences</h3>
          {(conferences || this.props.isEditable) && (
            <>
              {this.props.isEditable && (
                <button
                  className="btn btn--round"
                  type="button"
                  title="Ajouter une conférence"
                  onClick={this.handleAdd}
                >
                  <MdAdd />
                </button>
              )}
              {this.props.isEditable && conferences.length > 0 && (
                <button
                  className="btn btn--round"
                  type="button"
                  title="Modifier les conférences"
                  onClick={() =>
                    this.setState({ editing: !this.state.editing })
                  }
                >
                  <FiEdit3 />
                </button>
              )}
            </>
          )}
        </div>
        <div className="card card--conferences">
          {conferences.map((conf, index) => (
            <div className="div__conference" key={index}>
              {this.state.editing && (
                <div className="form__line">
                  <button
                    className="btn btn--round"
                    type="button"
                    title="Supprimer la conférence"
                    onClick={() => this.deleteConference(conf)}
                  >
                    <IoMdTrash />
                  </button>
                  <button
                    className="btn btn--round"
                    type="button"
                    title="Modifier les conférences"
                    onClick={() => this.handleEdit(conf)}
                  >
                    <FiEdit3 />
                  </button>
                </div>
              )}
              <div className="conference_year">
                <h2>{moment(conf.year).format("YYYY")}</h2>
              </div>
              <div className="div__conference__description">
                <div>
                  <span className="conference__name">{conf.place}</span>
                  <p>{conf.title}</p>
                </div>
                {(conf.video_link || conf.article_link) && (
                  <div className="conference__link">
                    {conf.video_link && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={conf.video_link}
                      >
                        Voir la vidéo
                      </a>
                    )}
                    {conf.article_link && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={conf.article_link}
                      >
                        Voir l'article
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
          {conferences.length === 0 && this.props.isEditable && (
            <div className="container">
              <i className="defaultPhrase">
                Vous avez été speaker ? Renseignez ici vos conférences.
              </i>
            </div>
          )}

          {conferences.length === 0 && !this.props.isEditable && (
            <div className="container">
              <i className="defaultPhrase">Aucune conférence renseignée.</i>
            </div>
          )}
        </div>
        {this.props.isEditable && (
          <AddConference
            clazz={this.state.clazz}
            toggleModal={this.toggleModalAdd}
            actualYear={moment().format("YYYY")}
            addConference={this.addConference}
            updateConference={this.updateConference}
            editing={this.state.editing}
            conf={this.state.conf}
          />
        )}
      </div>
    );
  }
}

export default Conferences;
