import React, { useState, useEffect, useContext, useRef } from "react";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { MdPlace } from "react-icons/md";
import moment from "moment";
import SerlianContext from "../../context";
import { getUserMissions } from "../../utils/fetch/fetchMissions";

moment.locale("fr");

const Missions = (props) => {
  const context = useContext(SerlianContext);
  const [missions, setMissions] = useState([]);
  const [firstCurrentMission, setFirstCurrentMission] = useState();
  const [firstFinishedMission, setFirstFinishedMission] = useState();
  const [idSerlian, setIdSerlian] = useState(props.idSerlian);
  const refClients = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [divClientsWidth, setDivClientsWidth] = useState(0);

  useEffect(() => {
    const container = refClients.current;

    const displayArrows = () => {
      if (container) {
        setShowLeftArrow(container.scrollLeft > 0);
        setShowRightArrow(
          container.scrollLeft + container.clientWidth < container.scrollWidth
        );
      }
    };

    displayArrows();

    const handleScroll = () => {
      displayArrows();
    };

    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
  }, [missions]);

  useEffect(() => {
    getUserMissions(props.idSerlian, (fetchedMissions) => {
      fetchedMissions.sort((a, b) =>
        a.current === b.current ? 0 : a.current ? -1 : 1
      );
      setMissions(fetchedMissions);
      const firstCurrent = fetchedMissions.findIndex(
        (mission) => mission.current === true
      );
      const firstFinished = fetchedMissions.findIndex(
        (mission) => mission.current === false
      );
      setFirstCurrentMission(firstCurrent);
      setFirstFinishedMission(firstFinished);
    });
  }, [props.idSerlian]);

  useEffect(() => {
    if (props.idSerlian !== idSerlian) {
      setIdSerlian(props.idSerlian);
    }
  }, [props.idSerlian, idSerlian]);

  const scrollLeft = () => {
    refClients.current.scrollLeft -= 160;
  };

  const scrollRight = () => {
    refClients.current.scrollLeft += 160;
  };

  return (
    missions &&
    missions.length > 0 && (
      <div className="container">
        <div className="section">
          <div className="section__header">
            <h3 className="section__header__title">Missions</h3>
          </div>
          <div className="div--arrow">
            <button
              onClick={scrollLeft}
              className="arrow arrow--left"
              style={{ display: showLeftArrow ? "block" : "none" }}
            >
              <FaArrowLeft />
            </button>
            <button
              onClick={scrollRight}
              className="arrow arrow--right"
              style={{ display: showRightArrow ? "block" : "none" }}
            >
              <FaArrowRight />
            </button>
          </div>
          <div className="div--clients" ref={refClients}>
            {missions.map((mission, index) => {
              let dateDebut = moment(mission.begin_time).format("MM.YYYY");
              let dateFin = moment(mission.end_time).format("MM.YYYY");
              return (
                <div key={index}>
                  {index === firstCurrentMission && (
                    <p className="situation-tag">En cours</p>
                  )}
                  {index === firstFinishedMission && (
                    <p className="situation-tag">Terminées</p>
                  )}
                  <div className="card card--client">
                    <div>
                      <div className="card--client__div--dates">
                        <p className="date--start">{dateDebut}</p>
                        <p className="date--end">
                          {mission.end_time &&
                          new Date().getTime() >
                            new Date(mission.end_time).getTime() ? (
                            <> - {dateFin} </>
                          ) : (
                            <> - en cours</>
                          )}
                        </p>
                      </div>
                      <div className="card--client__div--client">
                        <h3>{mission.client_name}</h3>
                        <p>
                          {mission.position_held ? mission.position_held : ""}
                        </p>
                      </div>
                    </div>
                    <div>
                      {mission.workplace && (
                        <span className="card--client__div--workplace">
                          <MdPlace className="icon--workplace" />
                          {mission.workplace}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    )
  );
};

export default Missions;
