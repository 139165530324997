import React, { Component } from "react";
import { FaCheck } from "react-icons/fa";
import { MdError } from "react-icons/md";

class Toast extends Component {
  constructor(props) {
    super(props);
  }

  renderIcon = () => {
    switch (this.props.type) {
      case "success":
        return <FaCheck />;
      case "error":
        return <MdError />;
    }
  };

  render() {
    return (
      <div className={"toast " + this.props.type} id="toast">
        <span>
          {this.renderIcon()} {this.props.title}
        </span>
        <p>{this.props.message}</p>
      </div>
    );
  }
}

export default Toast;
