import React, {Component} from 'react';
import Header from "../components/header/Header";
import SerlianContext from "../context";

class Page extends Component {

    static contextType = SerlianContext;

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <>
                <Header user={this.context.serlianConnected}/>
                { this.props.children }
            </>
        )
    }
}

export default Page;
